import styled from '@emotion/styled';
import { motion } from 'framer-motion';

export const StartButton = styled(motion.div)`
  --size: 144px;
  position: fixed;
  width: var(--size);
  height: var(--size);
  top: 20px;
  left: calc(50% - var(--size)/2);
  z-index: 999;

  @media (max-width: 1024px) {
    --size: 128px;
  }

  @media (max-width: 768px) {
    --size: 110px;
  }

  @media (max-height: 1024px) {
  }

  button {
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    p {
      position: absolute;
      color: #fff;
      z-index: 5;
      color: var(--Beige-Darker, #f5e7c9);
      text-align: center;
      font-family: 'Druk Text', 'Inter', sans-serif;
      font-style: italic;
      font-weight: 900;
      font-size: 1.15rem;
      line-height: 100%; /* 20.876px */
      letter-spacing: -0.209px;
      text-transform: uppercase;
      margin: 0;
      margin-bottom: 0.2em;

      @media (max-width: 1024px) {
        font-size: 1rem;
      }

      @media (max-width: 768px) {
        font-size: 0.875rem;
      }
    }

    &:hover {
      path {
        fill: var(--Beige-Darker, #f5e7c9);
      }

      .pulse {
        animation: none;
        transform: scale(1.12);
      }

      p {
        color: #6540B1;
      }
    }

    svg {
      width: 100%;
      height: 100%;
      // Spin and pulse avg with css animation
      animation: spin 10s linear infinite;
    }

    .pulse {
      animation: pulse 1.5s infinite;
      transform-origin: center;
      animation-delay: 1s;
    }

    path {
      transition: fill 0.3s;
    }

    @keyframes spin {
      100% {
        transform: rotate(360deg);
      }
    }

    @keyframes pulse {
      0% {
        transform: scale(1);
      }
      50% {
        transform: scale(1.1);
      }
      100% {
        transform: scale(1);
      }
    }
  }
`;
