export const initialShapesLarge = [
  {
    index: 0,
    isCircle: false,
    name: 'with08',
    url: 'https://holiday-2023.s3.amazonaws.com/words/initial-message/With.svg',
    width: 117.7630769726818,
    height: 64.76969233496575,
    angle: 0.0017154585756483426,
    position: {
      x: 1269.5066346818649,
      y: 568.4142697420591
    },
    scale: 73.60192310792613
  },
  {
    index: 1,
    isCircle: false,
    name: 'was07',
    url: 'https://holiday-2023.s3.amazonaws.com/words/initial-message/Was.svg',
    width: 105.15821536351984,
    height: 65.16847149288256,
    angle: 0.014864749925843775,
    position: {
      x: 1018.307957360232,
      y: 459.34546342513846
    },
    scale: 74.05508124191539
  },
  {
    index: 2,
    isCircle: false,
    name: 'more04',
    url: 'https://holiday-2023.s3.amazonaws.com/words/initial-message/More.svg',
    width: 169,
    height: 88,
    angle: 0.00907237128248076,
    position: {
      x: 815.5828460417674,
      y: 540.2735280387337
    },
    scale: 100
  },
  {
    index: 3,
    isCircle: false,
    name: 'to06',
    url: 'https://holiday-2023.s3.amazonaws.com/words/initial-message/To.svg',
    width: 72.62589646769018,
    height: 65.88741122842022,
    angle: 0.007444537036763917,
    position: {
      x: 548.6578823497467,
      y: 523.0690384375959
    },
    scale: 74.8720582141136
  },
  {
    index: 4,
    isCircle: false,
    name: 'huge101',
    url: 'https://holiday-2023.s3.amazonaws.com/words/initial-message/Hude.svg',
    width: 242.24543356177773,
    height: 132.40744194681355,
    angle: -10.011957744114788,
    position: {
      x: 1152.5631751306585,
      y: 419.5531662293232
    },
    scale: 150.4630022122843
  },
  {
    index: 5,
    isCircle: false,
    name: 'countdown113',
    url: 'https://holiday-2023.s3.amazonaws.com/stickers/countdown.svg',
    width: 170.99999999999926,
    height: 184.000000000002,
    angle: -16.758077175982628,
    position: {
      x: 442.3885661029102,
      y: 314.3367073802418
    },
    scale: 99.99999999999957
  },
  {
    index: 6,
    isCircle: false,
    name: 'Yay1117',
    url: 'https://holiday-2023.s3.amazonaws.com/stickers/Yay.svg',
    width: 142.9999999999996,
    height: 76.00000000000075,
    angle: -21.515606827729155,
    position: {
      x: 439.80103978494617,
      y: 663.933610331712
    },
    scale: 99.99999999999972
  },
  {
    index: 7,
    isCircle: false,
    name: 'excited00',
    url: 'https://holiday-2023.s3.amazonaws.com/words/initial-message/Excited.svg',
    width: 204.7963438490945,
    height: 79.04420288912607,
    angle: -6.803140619286261,
    position: {
      x: 316.41983318389237,
      y: 553.5619128510154
    },
    scale: 89.82295782855022
  },
  {
    index: 8,
    isCircle: false,
    name: 'this05',
    url: 'https://holiday-2023.s3.amazonaws.com/words/initial-message/This.svg',
    width: 127.60358033203381,
    height: 77.9799657584656,
    angle: -10.096690211198064,
    position: {
      x: 674.869304005579,
      y: 413.9991452656331
    },
    scale: 88.61359745280126
  },
  {
    index: 9,
    isCircle: false,
    name: 'make03',
    url: 'https://holiday-2023.s3.amazonaws.com/words/initial-message/Make.svg',
    width: 123.9332131815989,
    height: 66.09771369685355,
    angle: 6.78754624722606,
    position: {
      x: 659.2992167578842,
      y: 542.800586356434
    },
    scale: 75.11103829187812
  },
  {
    index: 10,
    isCircle: false,
    name: 'Wow1116',
    url: 'https://holiday-2023.s3.amazonaws.com/stickers/Wow.svg',
    width: 185.99999999999946,
    height: 76.00000000000057,
    angle: 22.163026586606122,
    position: {
      x: 1389.5317588118373,
      y: 354.7148113375922
    },
    scale: 99.99999999999972
  },
  {
    index: 11,
    isCircle: false,
    name: 'heart-green117',
    url: 'https://holiday-2023.s3.amazonaws.com/stickers/heart-green.svg',
    width: 117.69873334008788,
    height: 108.81581006913888,
    angle: -15.456621023090056,
    position: {
      x: 274.29604304353745,
      y: 675.7374321611826
    },
    scale: 111.03654088687536
  },
  {
    index: 12,
    isCircle: false,
    name: 'magic02',
    url: 'https://holiday-2023.s3.amazonaws.com/words/initial-message/Magic.svg',
    width: 230.5337565672688,
    height: 109.06973428989247,
    angle: 9.012614753105149,
    position: {
      x: 1004.1302514389159,
      y: 565.967181222563
    },
    scale: 123.94287987487569
  },
  {
    index: 13,
    isCircle: false,
    name: 'you010',
    url: 'https://holiday-2023.s3.amazonaws.com/words/initial-message/You.svg',
    width: 168.65282417685737,
    height: 113.29350021040939,
    angle: 12.266942822863943,
    position: {
      x: 1408.767570552472,
      y: 525.0187197926449
    },
    scale: 128.74261387546363
  },
  {
    index: 14,
    isCircle: false,
    name: 'LC2310',
    url: 'https://holiday-2023.s3.amazonaws.com/stickers/LC.svg',
    width: 178.99999999999903,
    height: 211.00000000000182,
    angle: 1.0795435689832407,
    position: {
      x: 863.8851588865255,
      y: 763.5198501280398
    },
    scale: 99.99999999999946
  },
  {
    index: 15,
    isCircle: false,
    name: 'year09',
    url: 'https://holiday-2023.s3.amazonaws.com/words/initial-message/Year.svg',
    width: 117.15019078757686,
    height: 66.51107606004477,
    angle: 10.831570924688382,
    position: {
      x: 833.2981913211537,
      y: 414.87770550567257
    },
    scale: 75.58076825004959
  },
  {
    index: 16,
    isCircle: false,
    name: 'diamond-1-pink285',
    url: 'https://holiday-2023.s3.amazonaws.com/additional-shapes/diamond-1-pink.svg',
    width: 69.63905923691485,
    height: 82.30070637090019,
    angle: 3.107023151149906,
    position: {
      x: 1044.2547752830437,
      y: 691.3025828316993
    },
    scale: 79.13529458740324
  },
  {
    index: 17,
    isCircle: false,
    name: 'heart-violet219',
    url: 'https://holiday-2023.s3.amazonaws.com/stickers/heart-violet.svg',
    width: 105.99999999999963,
    height: 98.00000000000111,
    angle: 17.74284918622354,
    position: {
      x: 864.7688873212486,
      y: 660.7095977091409
    },
    scale: 99.99999999999966
  },
  {
    index: 18,
    isCircle: false,
    name: 'lightning-2--1289',
    url: 'https://holiday-2023.s3.amazonaws.com/additional-shapes/lightning-2--1.svg',
    width: 57.207835173698676,
    height: 81.82892879275936,
    angle: 7.521427568941179,
    position: {
      x: 1397.7992554922137,
      y: 779.7713729909948
    },
    scale: 72.41498123252997
  },
  {
    index: 19,
    isCircle: false,
    name: 'lightning-2--22810',
    url: 'https://holiday-2023.s3.amazonaws.com/additional-shapes/lightning-2--2.svg',
    width: 66.20026697962155,
    height: 94.6915211227499,
    angle: 3.8792093594150603,
    position: {
      x: 772.5441814820394,
      y: 459.7361315516954
    },
    scale: 83.79780630331841
  },
  {
    index: 20,
    isCircle: false,
    name: 'diamond-1-chartreuse281',
    url: 'https://holiday-2023.s3.amazonaws.com/additional-shapes/diamond-1-chartreuse.svg',
    width: 41.158290449697546,
    height: 49.200715020328595,
    angle: 5.237330004072577,
    position: {
      x: 1272.8086879963435,
      y: 317.3511953454793
    },
    scale: 47.308379827238554
  },
  {
    index: 21,
    isCircle: false,
    name: 'cheers112',
    url: 'https://holiday-2023.s3.amazonaws.com/stickers/cheers.svg',
    width: 219.99999999999915,
    height: 75.00000000000122,
    angle: -10.812600680935216,
    position: {
      x: 1020.8181798974113,
      y: 348.4501694071915
    },
    scale: 99.99999999999962
  },
  {
    index: 22,
    isCircle: false,
    name: 'lightning-2-purple2816',
    url: 'https://holiday-2023.s3.amazonaws.com/additional-shapes/lightning-2-purple.svg',
    width: 65.8625881894152,
    height: 94.20851222030439,
    angle: 4.2038607510432815,
    position: {
      x: 520.3373723116182,
      y: 760.3641659893002
    },
    scale: 83.37036479672811
  },
  {
    index: 23,
    isCircle: false,
    name: 'diamond-1-orange353',
    url: 'https://holiday-2023.s3.amazonaws.com/additional-shapes/diamond-1-orange.svg',
    width: 72.11093125514155,
    height: 85.22200966516915,
    angle: -0.6001443274174436,
    position: {
      x: 645.7811863697327,
      y: 294.57805113392993
    },
    scale: 81.94424006266085
  },
  {
    index: 24,
    isCircle: false,
    name: '2024-glasses110',
    url: 'https://holiday-2023.s3.amazonaws.com/stickers/2024-glasses.svg',
    width: 171.9999999999997,
    height: 127.00000000000057,
    angle: -0.02584766542886093,
    position: {
      x: 1507.7740901550164,
      y: 476.884214194756
    },
    scale: 99.99999999999983
  },
  {
    index: 25,
    isCircle: false,
    name: 'thank-you1115',
    url: 'https://holiday-2023.s3.amazonaws.com/stickers/thank-you.svg',
    width: 210,
    height: 139,
    angle: 0.007054783656818547,
    position: {
      x: 621.6255257668261,
      y: 679.5288863361375
    },
    scale: 100
  },
  {
    index: 26,
    isCircle: false,
    name: 'peace-hand1112',
    url: 'https://holiday-2023.s3.amazonaws.com/stickers/peace-hand.svg',
    width: 83.99999999999986,
    height: 111.00000000000134,
    angle: -0.005051291587130916,
    position: {
      x: 891.9635098298219,
      y: 288.6408804876541
    },
    scale: 99.99999999999983
  },
  {
    index: 27,
    isCircle: true,
    name: 'happy-new-year115',
    url: 'https://holiday-2023.s3.amazonaws.com/stickers/happy-new-year.svg',
    width: 190.99999999999974,
    height: 191.00000000000128,
    angle: 0.21384902260847202,
    position: {
      x: 1152.1791452671182,
      y: 666.0856216853306
    },
    scale: 99.99999999999987
  },
  {
    index: 28,
    isCircle: false,
    name: 'DreamTeam114',
    url: 'https://holiday-2023.s3.amazonaws.com/stickers/DreamTeam.svg',
    width: 165.9999999999999,
    height: 122.0000000000006,
    angle: 0.02967177976715995,
    position: {
      x: 1438.6596813443127,
      y: 637.0513698274533
    },
    scale: 99.99999999999993
  }
];
