import { useState, useEffect, useRef, useCallback } from 'react';
import { Composite, Engine, Render, Bodies, Body, Runner, Sleeping } from 'matter-js';

import {
  setupMatterJs,
  matterJsBodyOptions,
  findRandomPosition,
  loadAndSizeImage,
  loadSVGImage,
  scalePosition,
  unscalePosition,
  removeQueryString,
  waitForBodiesToSettle,
  type rawDataShape
} from '../utils';

import { ControlPanel } from './ControlPanel';
import { ItemLoader } from './ItemLoader';
import { KonvaStage } from './KonvaStage';

import { getShapesToAdd } from '../App';

import { additionalShapes } from '../data/shapes';
import { circles, tvs, pills, random1, random2 } from '../data/stickers';
import { initialWords as words0, words1, words2, words3, words4, words5, words6, words7, words8 } from '../data/words';
import { bgs } from '../data/bgs';

const isDebug = false;
const wordCollection = [words0, words1, words2, words3, words4, words5, words6, words7, words8];
const stickerCollection = [circles, tvs, pills, random1, random2];

interface MatterShape {
  width: number;
  height: number;
  scale: number;
  id: string;
  isCircle: boolean;
  body: any;
}

interface CardCanvasProps {
  cardStarted: boolean;
  startPlaying: boolean;
  createCardInitialized?: () => void;
}

const CardCanvas = ({ cardStarted, startPlaying, createCardInitialized }: CardCanvasProps) => {
  // Matterjs engine
  const [engine, setEngine] = useState<Engine | null>(null);
  // Stage dimensions
  const [stageDimensions, setStageDimensions] = useState({ width: window.innerWidth, height: window.innerHeight });

  // Matterjs shapes
  const [matterShapes, setMatterShapes] = useState<any>([]);

  // Konva shapes
  const [konvaShapes, setKonvaShapes] = useState<any[]>([]);
  const [selectedShapes, setSelectedShapes] = useState<any[]>([]);

  // Whether or not the shapes are currently falling
  const [isFalling, setIsFalling] = useState(false);

  // Whether or not the shapes are currently falling
  const [isAddingItems, setIsAddingItems] = useState(false);

  const [randomBackground, setRandomBackground] = useState<string>('');

  // Keep track of the words added
  const [addWordsCount, setAddWordsCount] = useState(0);
  const [addStickersCount, setAddStickersCount] = useState(0);
  const [addBackgroundCount, setAddBackgroundCount] = useState(0);

  // Matterjs refs
  const runnerRef = useRef<any>(null);
  const renderRef = useRef<any>(null);
  const engineRef = useRef(engine);
  const updateDimensionsRef = useRef<any>(null);
  // Konva stage
  const stageRef = useRef<any>(null);

  const matterShapesRef = useRef(matterShapes);
  const konvaShapesRef = useRef(konvaShapes);
  const selectionRectRef = useRef<any>(null);

  const startPlayingRef = useRef(startPlaying);

  // Keep the ref updated
  useEffect(() => {
    matterShapesRef.current = matterShapes;
  }, [matterShapes]);

  useEffect(() => {
    konvaShapesRef.current = konvaShapes;
  }, [konvaShapes]);

  useEffect(() => {
    engineRef.current = engine;
  }, [engine]);

  // Set the Konva shapes to match the Matter-js shapes
  const syncKonvaShapesToMatterShapes = useCallback(() => {
    const newKonvaShapes = matterShapesRef.current
      .filter(({ body }: MatterShape) => body.label === 'shape')
      .map(({ body, width, height, scale, id, isCircle }: MatterShape) => {
        return {
          id: id,
          konvaId: id,
          x: body.position.x,
          y: body.position.y,
          width: width,
          height: height,
          angle: body.angle * (180 / Math.PI), // Conversion here
          url: `${body?.render?.sprite?.texture}?stopit=true`,
          scale: scale,
          isCircle: isCircle
        };
      });
    console.log('🚀 ~ file: CardCanvas.tsx:51 ~ syncKonvaShapesToMatterShapes ~ newKonvaShapes:', newKonvaShapes);
    setKonvaShapes(newKonvaShapes || []);
  }, []);

  const updateMatterShape = (id: any, konvaShapeProps: any) => {
    const eng = engineRef.current;
    if (!eng) return;

    const { x, y, width, height, angle, url, scale, isCircle } = konvaShapeProps;

    // Find and remove the old Matter.js body
    const oldMatterShape = matterShapesRef.current.find((shape: any) => shape.id === id);
    if (oldMatterShape) {
      Composite.remove(eng.world, oldMatterShape.body);
    }

    const xScale = scale / 100;
    const yScale = scale / 100;

    let newBody: any;

    const options = {
      isStatic: true,
      render: { sprite: { texture: url, xScale, yScale } },
      ...matterJsBodyOptions
    };

    if (isCircle) {
      newBody = Bodies.circle(x, y, width / 2, options);
    } else {
      newBody = Bodies.rectangle(x, y, width, height, options);
    }

    // Converting angle to radians and setting it
    const angleInRadians = angle * (Math.PI / 180);
    Body.setAngle(newBody, angleInRadians);

    // Add the new body to the world
    Composite.add(eng.world, [newBody]);

    // Update the matterShapes state
    setMatterShapes((prevShapes: any) => {
      const newMatterShapes = prevShapes.map((shape: MatterShape) =>
        shape.id === id ? { ...shape, width, height, scale, body: newBody } : shape
      );
      return newMatterShapes;
    });
  };

  const applyMagnets = useCallback(() => {
    // if (!isFalling) return;
    const eng = engineRef.current;
    if (!eng) return;

    const bodyShapes = eng.world.bodies.filter((body) => body.label === 'shape');
    // set all bodies to static
    bodyShapes.forEach((body) => {
      if (!body.isStatic) {
        Body.setStatic(body, true);
      }
      body.restitution = 0.4;
    });
    // Set all of the bodies to static
    syncKonvaShapesToMatterShapes(); // Synchronize the positions after all shapes have settled
    setIsFalling(false);
  }, [syncKonvaShapesToMatterShapes]);

  const applyGravity = useCallback(() => {
    const eng = engineRef.current;
    if (!eng) return;
    eng.world.gravity.y = 1;

    const bodyShapes = eng.world.bodies.filter((body) => body.label === 'shape');
    // set all bodies to falling
    bodyShapes.forEach((body) => {
      body.restitution = 0.4;
      if (body.isStatic) {
        Body.setStatic(body, false);
      }
      Sleeping.set(body, false);
    });
    setIsFalling(true);
  }, []);

  const floatShapes = useCallback(() => {
    const eng = engineRef.current;
    if (!eng) return;

    // Adjust the gravity to simulate the moon's gravity
    eng.world.gravity.y = 0.0006;

    const bodyShapes = eng.world.bodies.filter((body) => body.label === 'shape');
    bodyShapes.forEach((body) => {
      if (body.isStatic) {
        Body.setStatic(body, false);
      }
      Sleeping.set(body, false);
      // Adjust for less bouncy interactions
      body.restitution = 0.3; // Lower restitution for a more floaty behavior

      // Apply a gentler force
      const upwardAngle = Math.PI / 2; // Directly upward
      const magnitude = 0.05; // Smaller magnitude for gentler force
      const force = {
        x: magnitude * Math.cos(upwardAngle),
        y: magnitude * Math.sin(upwardAngle) * -1 // Ensure upward force
      };
      Body.applyForce(body, body.position, force);
    });

    setIsFalling(true);
  }, []);

  const explodeShapes = useCallback(() => {
    const eng = engineRef.current;
    if (!eng) return;
    eng.world.gravity.y = 1;

    const bodyShapes = eng.world.bodies.filter((body) => body.label === 'shape');
    bodyShapes.forEach((body) => {
      if (body.isStatic) {
        Body.setStatic(body, false);
      }
      Sleeping.set(body, false);
      // Make bodies more bouncy
      body.restitution = 0.8; // Adjust the bounciness as needed
      const upwardAngle = Math.random() * (Math.PI / 3) + Math.PI / 3; // Random angle between π/3 and 2π/3
      const randomAngle = Math.random() * 2 * Math.PI; // Random angle between 0 and 2π
      const magnitude = Math.random() * (3.5 - 1.5) + 0.5; // Random magnitude between 0.5 and 1.5
      const angle = isFalling ? upwardAngle : randomAngle;
      const force = {
        x: magnitude * Math.cos(angle),
        y: magnitude * Math.sin(angle) * -1 // Still need to ensure upward force
      };
      Body.applyForce(body, body.position, force);
    });

    setIsFalling(true);
  }, [isFalling]);

  const addItems = useCallback(
    async (itemsToAdd: rawDataShape[]) => {
      const eng = engineRef.current;
      if (!eng || isAddingItems) return;
      setIsFalling(true);
      eng.world.gravity.y = 1;
      setIsAddingItems(true);
      let topWallBody: any = null;
      const newMatterjsShapes: any[] = [];

      // update restitution
      const bodyShapes = eng.world.bodies.filter((body) => body.label === 'shape');
      bodyShapes.forEach((body) => {
        body.restitution = 0.4;
      });

      // remove the top wall
      eng?.world.bodies.forEach((body) => {
        if (body.label === 'topWall') {
          topWallBody = body;
          Composite.remove(eng.world, body);
        }
      });
      await Promise.all(
        itemsToAdd.map(async (node, index) => {
          try {
            const { width, height, scale } = await loadAndSizeImage(node);
            const { x, y } = findRandomPosition(newMatterjsShapes, { width, height });
            const aboveCanvasY = y * -1.25;
            const adjustedY = aboveCanvasY;
            // Calculate the scale factors for the sprite texture considering the device pixel ratio
            const xScale = scale.x;
            const yScale = scale.y;

            let body;

            if (node.isCircle) {
              body = Bodies.circle(x, adjustedY, width / 2, {
                render: { sprite: { texture: node.url, xScale, yScale } },
                isStatic: false,
                ...matterJsBodyOptions
              });
            } else {
              // Create the Matter.js body with the scaled position and size
              body = Bodies.rectangle(x, adjustedY, width, height, {
                render: { sprite: { texture: node.url, xScale, yScale } },
                isStatic: false,
                chamfer: {
                  radius: [25, 25, 25, 25]
                },
                ...matterJsBodyOptions
              });
            }

            newMatterjsShapes.push({
              isCircle: node?.isCircle || false,
              width: width,
              height: height,
              scale: xScale * 100,
              id: `${node.name}-${matterShapesRef.current.length}-${index}`.replace(/ /g, '-'),
              body
            });
            Composite.add(eng.world, [body]);
          } catch (error) {
            console.error(error);
          }
        })
      );

      // Add the top wall back after a delay
      setTimeout(() => {
        Composite.add(eng.world, [topWallBody]);
        // setIsAddingItems(false);

        waitForBodiesToSettle(eng, 3000, () => {
          setIsAddingItems(false);
          // Set all of the bodies to static
          applyMagnets();
        });
      }, 2000);

      setMatterShapes([...matterShapesRef.current, ...newMatterjsShapes]);
    },
    [isAddingItems, applyMagnets]
  );

  const addWords = useCallback(async () => {
    addItems(wordCollection[addWordsCount]);
    if (addWordsCount >= wordCollection.length - 1) {
      setAddWordsCount(0);
    } else {
      setAddWordsCount(addWordsCount + 1);
    }
  }, [addItems, addWordsCount]);

  const addShapes = useCallback(async () => {
    addItems(additionalShapes);
  }, [addItems]);

  const addStickers = useCallback(async () => {
    addItems(stickerCollection[addStickersCount]);
    if (addStickersCount >= stickerCollection.length - 1) {
      setAddStickersCount(0);
    } else {
      setAddStickersCount(addStickersCount + 1);
    }
  }, [addItems, addStickersCount]);

  const deleteSelectedShapes = useCallback(() => {
    const eng = engineRef.current;
    if (!eng || selectedShapes.length === 0) return;

    const newKonvaShapes = konvaShapesRef.current.filter((shape) => !selectedShapes.includes(shape.id));
    setKonvaShapes(newKonvaShapes);

    const newMatterShapes = matterShapesRef.current.filter((shape: any) => !selectedShapes.includes(shape.id));
    const deletedMatterShapes = matterShapesRef.current.filter((shape: any) => selectedShapes.includes(shape.id));

    deletedMatterShapes.forEach((shape: any) => {
      if (shape.body) {
        Composite.remove(eng.world, shape.body);
      }
    });

    setMatterShapes(newMatterShapes);
    setSelectedShapes([]);
  }, [selectedShapes]);

  // Update the stage dimensions on resize
  useEffect(() => {
    const handleResize = () => {
      const newWidth = window.innerWidth;
      const newHeight = window.innerHeight;

      updateDimensionsRef.current();

      const calculateNewPositions = (newWidth: number, newHeight: number, shapes: any) => {
        const originalWindowWidth = stageDimensions.width;
        const originalWindowHeight = stageDimensions.height;
        const offsetX = (newWidth - originalWindowWidth) / 2;
        const offsetY = (newHeight - originalWindowHeight) / 2;

        return shapes.map((shape: any) => {
          return {
            ...shape,
            x: shape.x + offsetX,
            y: shape.y + offsetY
          };
        });
      };

      if (!isFalling && konvaShapesRef.current.length > 0) {
        // Update the konva shapes
        const newKonvaShapes = calculateNewPositions(newWidth, newHeight, konvaShapesRef.current);
        console.log('Setting the konva shapes to', konvaShapesRef.current);
        setKonvaShapes(newKonvaShapes);

        // Update the matterjs shapes
        newKonvaShapes.forEach((shape: any) => {
          updateMatterShape(shape.id, shape);
        });
      } else if (isFalling) {
        applyGravity();
      }

      setStageDimensions({
        width: newWidth,
        height: newHeight
      });
    };

    window.addEventListener('resize', handleResize);
    window.addEventListener('orientationchange', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('orientationchange', handleResize);
    };
  }, [stageDimensions, applyGravity, isFalling]);

  // Delete the selected shape
  useEffect(() => {
    const deleteKeyShape = (evt: KeyboardEvent) => {
      if (evt.key === 'Backspace' || evt.key === 'Delete') {
        deleteSelectedShapes();
      }
    };
    window.addEventListener('keydown', deleteKeyShape);
    return () => {
      window.removeEventListener('keydown', deleteKeyShape);
    };
  }, [deleteSelectedShapes]);

  // Create a new Matter-js engine and render
  useEffect(() => {
    const { engine, render, runner, updateDimensions } = setupMatterJs();

    renderRef.current = render;
    runnerRef.current = runner;
    engineRef.current = engine;
    updateDimensionsRef.current = updateDimensions;
    setEngine(engine);

    return () => {
      Render.stop(render);
      // Composite.clear(runnerRef.current.world, true);
      Runner.stop(runnerRef.current);
      // Engine.clear(runnerRef.current);
      setEngine(null);
    };
  }, []);

  // Add the initial bodies to the canvas
  useEffect(() => {
    const eng = engineRef.current;
    if (!eng) return;
    const newMatterjsShapes: any[] = [];

    const canvasWidth = window.innerWidth;
    const canvasHeight = window.innerHeight;

    const isTablet = canvasWidth > 768 && canvasWidth <= 1439;
    const isPortait = canvasHeight > canvasWidth;

    let shapesToAdd = getShapesToAdd();
    // const scaleFactor = isTablet ? 1.75 : 1;
    let scaleFactor = 1;

    // Smaller Desktop
    if (canvasWidth <= 1440) {
      scaleFactor = 0.85;
    }

    // Small laptop or landscape tablet
    if (canvasWidth >= 1024 && canvasHeight <= 800) {
      scaleFactor = 0.65;
    }

    if (isTablet && isPortait) {
      scaleFactor = 1.75;
    }

    if (isPortait && canvasHeight <= 736) {
      scaleFactor = 0.75;
    }

    // Determine a reference point (e.g., center of the canvas)
    const referencePoint = { x: canvasWidth / 2, y: canvasHeight / 2 };

    /**
     * Add the main message nodes
     */
    shapesToAdd.forEach(async (node) => {
      const initialPosition = scalePosition(node, canvasWidth, canvasHeight, 1920, 1080, node.position);

      // Calculate original relative position to the reference point
      const originalRelativePosition = {
        x: initialPosition.x - referencePoint.x,
        y: initialPosition.y - referencePoint.y
      };

      // Scale the relative position
      const scaledRelativePosition = {
        x: originalRelativePosition.x * scaleFactor,
        y: originalRelativePosition.y * scaleFactor
      };

      // Apply the scaled position
      const position = {
        x: referencePoint.x + scaledRelativePosition.x,
        y: referencePoint.y + scaledRelativePosition.y
      };

      try {
        const { scale } = await loadSVGImage(node);
        // Converting angle to radians and setting it
        // const angleInRadians = node.angle * (Math.PI / 180) * -1; // Convert angle to radians
        const angleInRadians = node.angle * (Math.PI / 180); // Convert angle to radians

        // Scale up the width and height by 50%
        const scaledWidth = node.width * scaleFactor;
        const scaledHeight = node.height * scaleFactor;

        // Adjust the scale factors for the sprite texture
        const xScale = scale.x * scaleFactor;
        const yScale = scale.y * scaleFactor;

        let body;

        if (node.isCircle) {
          body = Bodies.circle(position.x, position.y, scaledWidth / 2, {
            render: { sprite: { texture: node.url, xScale, yScale } },
            angle: angleInRadians,
            isStatic: true,
            ...matterJsBodyOptions
          });
        } else {
          // Create the Matter.js body with the scaled position and size
          body = Bodies.rectangle(position.x, position.y, scaledWidth, scaledHeight, {
            render: { sprite: { texture: node.url, xScale, yScale } },
            angle: angleInRadians,
            isStatic: true,
            chamfer: {
              radius: [25, 25, 25, 25]
            },
            ...matterJsBodyOptions
          });
        }

        // Add the body to the Matter.js world or engine
        newMatterjsShapes.push({
          width: scaledWidth,
          height: scaledHeight,
          scale: xScale * 100,
          isCircle: node?.isCircle || false,
          id: node.name,
          body
        });
        Composite.add(eng.world, [body]);
        Sleeping.set(body, false); // sleep
      } catch (error) {
        console.error(error);
      }
    });

    setMatterShapes(newMatterjsShapes);

    // Set the initial konva shapes
    setTimeout(() => {
      syncKonvaShapesToMatterShapes();
    }, 1000);
  }, [syncKonvaShapesToMatterShapes]);

  const exportShapes = () => {
    const shapesExport = konvaShapesRef.current.map((shape, index) => {
      const { x, y, width, height, angle, url, scale, isCircle } = shape;
      const unscaledPosition = unscalePosition(shape, { x, y });

      return {
        index,
        isCircle: isCircle,
        name: shape.id,
        url: removeQueryString(url),
        width,
        height,
        angle,
        position: {
          ...unscaledPosition
        },
        scale
      };
    });

    console.log('🟧 shapesExport:', shapesExport);
  };

  const exportImage = () => {
    setSelectedShapes([]);
    setTimeout(() => {
      const dataURL = stageRef.current.toDataURL({ pixelRatio: 2 });
      const link = document.createElement('a');
      link.download = 'LittleCinema-ThankYou-2023.jpg';
      link.href = dataURL;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }, 1000);
  };

  const clearAll = () => {
    const eng = engineRef.current;
    if (!eng) return;
    const bodyShapes = eng.world.bodies.filter((body) => body.label === 'shape');
    bodyShapes.forEach((body) => {
      Composite.remove(eng.world, body);
    });
    setMatterShapes([]);
    setKonvaShapes([]);
    setSelectedShapes([]);
  };

  // Apply gravity when the card starts
  useEffect(() => {
    // make sure this can only happen once
    if (startPlaying && startPlayingRef.current !== startPlaying) {
      startPlayingRef.current = startPlaying;
      explodeShapes();
      waitForBodiesToSettle(engineRef.current, 5000, () => {
        applyMagnets();
        setRandomBackground(bgs[0].url);

        if (createCardInitialized) {
          createCardInitialized();
        }
      });
    }
  }, [startPlaying, explodeShapes, applyMagnets, createCardInitialized]);

  return (
    <div className="card-canvas">
      {cardStarted && (
        <ControlPanel
          onEditMode={applyMagnets}
          onPlayMode={applyGravity}
          onBlowUp={explodeShapes}
          onGravity={applyGravity}
          onAddWords={addWords}
          onAddShapes={addShapes}
          onAddStickers={addStickers}
          onMoonWalk={floatShapes}
          onExport={exportImage}
          onExportData={exportShapes}
          shapeSelected={!!selectedShapes.length}
          isEditMode={!isFalling}
          isAddingItems={isAddingItems}
          deleteShape={deleteSelectedShapes}
          onClearAll={clearAll}
          onRandomBackground={() => {
            const bgCount = addBackgroundCount + 1 >= bgs.length ? 0 : addBackgroundCount + 1;
            const randomBg = bgs[bgCount];
            setAddBackgroundCount(bgCount);
            setRandomBackground(randomBg.url);
          }}
          wordCount={addWordsCount > 0 ? `${addWordsCount}/${wordCollection.length}` : ''}
          stickerCount={addStickersCount > 0 ? `${addStickersCount}/${stickerCollection.length}` : ''}
          backgroundCount={addBackgroundCount > 0 ? `${addBackgroundCount}/${bgs.length - 1}` : ``}
        />
      )}

      <div
        style={{
          pointerEvents: !cardStarted ? 'none' : 'auto'
        }}
      >
        <div
          className="canvas-overlay"
          style={{
            backgroundImage: `url('${randomBackground}')`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundAttachment: 'fixed',
            opacity: isFalling ? 0.5 : 0,
            zIndex: isFalling ? 1 : -1,
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0
          }}
        ></div>
        {/* This is the Matter-js Container */}
        <div
          id="canvasContainer"
          className="matter-canvas"
          style={{
            opacity: isFalling ? 1 : isDebug ? 0.25 : 0,
            zIndex: isFalling ? 10 : -1,
            background: 'transparent',
            position: 'absolute'
          }}
        ></div>
        {/* This is the Konva Container */}
        <KonvaStage
          stageDimensions={stageDimensions}
          selectionRectRef={selectionRectRef}
          // isFalling={isFalling || !cardStarted}
          isFalling={isFalling}
          konvaShapes={konvaShapes}
          setKonvaShapes={setKonvaShapes}
          selectedShapes={selectedShapes}
          setSelectedShapes={setSelectedShapes}
          updateMatterShape={updateMatterShape}
          deleteSelectedShapes={deleteSelectedShapes}
          stageRef={stageRef}
          backgroundImage={randomBackground}
        />
        {/* // Add loader when adding items */}
        <ItemLoader isOpen={isAddingItems} />
      </div>
    </div>
  );
};

export default CardCanvas;
