import styled from '@emotion/styled';
import { motion } from 'framer-motion';

export const ItemLoader = styled(motion.div)<any>`
  font-size: 16px;
  :root {
    --switch-width: 2.5em;
    --switch-height: calc(var(--switch-width) / 2);
  }

  position: absolute;
  top: 1em;
  left: calc(50% - 21.25rem/2);
  background: var(--Black, #000000be);
  border-radius: 3px;
  font: 1rem 'Inter', Arial, Helvetica, sans-serif;
  font-weight: 500;
  z-index: 1;
  border-radius: 1.75em;
  border: 2px solid rgba(255,255,255,0.75);
  padding: 0.75em 1.5em;
  z-index: 999;
  width: 21.25em;
  color: #f1f1f1;
  box-shadow: 6px 6px 8px rgb(0 0 0 / 20%);

  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 768px) {
    box-sizing: border-box;
    font-size: 14px;
    top: 62px;
    top: ${({ topSmall }) => topSmall};
    left: calc(50% - 48%);
    width: 96%;
  }

  @media (max-height: 1024px) {
    font-size: 12px;
  }
`;

export const Loader = styled.div`
  position: relative;
  @keyframes spinner {
    to {
      transform: rotate(360deg);
    }
  }

  &:before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-left: -10px;
    border-radius: 50%;
    border: 3px solid #343434;
    border-top-color: #6540b1;
    border-right-color: #6540b1;
    /* border-right-color: #0dd;
    border-bottom-color: #f90; */
    animation: spinner 0.6s linear infinite;
  }
`;
