import * as Styled from './StartButton.styles';

interface StartButtonProps {
  onClick: () => void;
}

export const StartButton = ({ onClick }: StartButtonProps) => {
  return (
    <Styled.StartButton>
      <button onClick={onClick}>
        <p>
          Start
          <br /> Playing!
        </p>
        <div className="pulse">
          <svg xmlns="http://www.w3.org/2000/svg" width="151" height="151" viewBox="0 0 151 151" fill="none">
            <path
              d="M78.3659 2.55469C64.2945 2.55469 56.1605 14.1456 53.8524 19.941C49.528 17.6548 37.9344 14.454 26.1552 19.941C14.3759 25.4281 18.1166 43.3887 21.4594 51.6831C14.6147 51.6831 1.35503 56.6597 3.07417 76.5663C4.79331 96.4729 16.0473 98.3125 21.4594 96.744C17.984 102.034 14.0575 115.438 26.1552 126.732C38.2528 138.025 49.6606 133.351 53.8524 129.603C53.8524 134.999 58.3094 146.16 76.1374 147.627C93.9655 149.095 97.9981 136.222 97.7858 129.603C103.304 131.517 116.792 133.622 126.597 126.732C136.403 119.841 132.858 103.869 129.86 96.744C134.901 96.0263 145.555 90.9858 147.848 76.5663C150.14 62.1468 136.811 53.9694 129.86 51.6831C132.381 47.6954 135.909 37.136 129.86 26.7999C123.812 16.4638 105.957 17.9206 97.7858 19.941C97.1756 14.1456 92.4374 2.55469 78.3659 2.55469Z"
              fill="#6540B1"
              stroke="#F5E7C9"
              strokeWidth="4.56967"
            />
          </svg>
        </div>
      </button>
    </Styled.StartButton>
  );
};
