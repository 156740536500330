import styled from '@emotion/styled';
import { motion } from 'framer-motion';

export const IntroModal = styled(motion.div)`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9998;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
  overflow-x: hidden;

  @media (max-width: 768px) {
    align-items: flex-start;
  }
`;

export const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.5;
  z-index: 1;
`;

export const Card = styled.div`
  position: relative;
  width: calc(100% - 4rem);
  max-width: 675px;
  border-radius: 10px;
  padding: 3rem 2rem;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;

  border-radius: 1rem;
  background: #0e0e0e;

  /* GLOW */
  box-shadow: 0px 0px 60px 0px rgba(157, 108, 255, 0.4);

  @media (max-width: 768px) {
    padding: 1rem;
    margin: 2rem;
    gap: 1rem;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    text-align: center;

    > * {
      margin: 0;
    }

    h1 {
      font-family: 'Druk Text', 'Inter', sans-serif;
      color: var(--Beige, #fbf8f0);
      text-align: center;
      font-size: 2.175rem;
      font-style: normal;
      font-weight: 500;
      line-height: 100%; /* 2.17994rem */
      text-transform: uppercase;
    }

    p {
      color: var(--Caption, #9b9b9b);
      text-align: center;
      font-family: Inter;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    @media (max-width: 768px) {
      h1 {
        font-size: 2.125rem;
      }

      p {
        font-size: 0.75rem;
      }
    }
  }

  .swiper-wrapper {
    display: flex;
    align-items: center;
  }

  .swiper-slide {
    height: auto;
  }
`;

export const Button = styled.button`
  display: flex;
  height: 2rem;
  min-height: 2rem;
  padding: 0.22281rem 0.61913rem;
  justify-content: center;
  align-items: center;
  gap: 0.06194rem;
  flex: 1 0 0;

  border-radius: 0.99088rem;
  border: 1.585px solid var(--Purple-LC, #9d6cff);
  box-shadow: 0px 2.377px 7.13px 0px rgba(29, 29, 29, 0.2);

  color: var(--Beige, #fbf8f0);
  text-align: center;
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 0.6875rem */
  text-transform: capitalize;
  flex: 1 0 0;
  background: transparent;
  cursor: pointer;

  @media (min-width: 768px) {
    height: 1.98175rem;
    padding: 0.22281rem 0.61913rem;
  }

  &:hover {
    background: var(--Purple-LC, #9d6cff);
    color: var(--Beige, #fbf8f0);
  }
`;

export const SkipButton = styled(Button)`
  border-radius: 0.99088rem;
  border: 0.793px solid var(--Beige, #fbf8f0);

  &:hover {
    border: 1.585px solid var(--Purple-LC, #9d6cff);
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: 100%;
  flex-direction: row;

  > * {
    width: 20rem;
  }

  @media (max-width: 768px) {
    gap: 0.5rem;
    flex-direction: column;
    > * {
      width: 100%;
    }
  }
`;

export const Slide = styled.div`
  display: flex;
  /* height: 16.54769rem; */
  padding: 2.47719rem 1.48631rem;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 0.74319rem;
  background: #232323;
  margin-bottom: 2.5rem;

  @media (max-width: 768px) {
    padding: 1.8rem 1.2rem;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  max-width: 30.41694rem;
  margin: 0 auto;
`;

export const Text = styled.p`
  color: var(--Caption, #9b9b9b);
  text-align: center;
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 130%; /* 1.3rem */
`;

export const Title = styled.h1`
  color: var(--Caption, #9b9b9b);
  text-align: center;
  font-family: 'Druk Text Wide', 'Inter', sans-serif;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 0.875rem */
  text-transform: uppercase;
`;

export const Image = styled.img`
  width: 100%;
  max-width: 620px;
`;

export const Instructions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 1rem;
  margin: 0 auto;

  @media (min-width: 768px) {
    gap: 2rem;
    flex-direction: row;
  }
`;

export const TutImage = styled.img`
  width: 100%;
  max-width: 330px;
  height: auto;
`;
export const ButtonImage = styled.img`
  max-width: 100%;
  height: auto;
`;

export const TutTitle = styled.h1`
  color: var(--Caption, #9b9b9b);
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 130%; /* 1.3rem */
`;
export const TutText = styled.p`
  color: var(--Caption, #9b9b9b);
  font-family: Inter;
  font-size: 0.6875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 0.89375rem */
`;

export const RightSideSlide = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
`;
