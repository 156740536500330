export const bgs = [
  {
    url: 'https://holiday-2023.s3.amazonaws.com/card-main-bg.jpg?stopit=true'
  },
  {
    url: 'https://holiday-2023.s3.amazonaws.com/bgs/bg1.webp'
  },
  {
    url: 'https://holiday-2023.s3.amazonaws.com/bgs/bg2.webp'
  },
  {
    url: 'https://holiday-2023.s3.amazonaws.com/bgs/bg3.webp'
  },
  {
    url: 'https://holiday-2023.s3.amazonaws.com/bgs/bg4.webp'
  },
  {
    url: 'https://holiday-2023.s3.amazonaws.com/bgs/bg5.webp'
  },
  {
    url: 'https://holiday-2023.s3.amazonaws.com/bgs/bg6.webp'
  },
  {
    url: 'https://holiday-2023.s3.amazonaws.com/bgs/bg7.webp'
  },
  {
    url: 'https://holiday-2023.s3.amazonaws.com/bgs/bg8.webp'
  },
  {
    url: 'https://holiday-2023.s3.amazonaws.com/bgs/bg9.webp'
  },
  {
    url: 'https://holiday-2023.s3.amazonaws.com/bgs/bg10.webp'
  },
] as const;
