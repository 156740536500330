/// <reference types="react-scripts" />

import React, { useEffect, useState, createContext } from 'react';
// import logo from './logo.svg';
import './App.css';
import CardCanvas from './components/CardCanvas';
import { MainLoader } from './components/MainLoader';
import { initialShapesLarge } from './data/initialShapesLarge';
import { initialShapesSmall } from './data/initialShapesSmall';
import { StartButton } from './components/StartButton';
import { IntroModal } from './components/IntroModal';
import { AnimatePresence, motion } from 'framer-motion';
import { ItemLoader } from './components/ItemLoader';

export const LoadingContext = createContext(null);

// const isDebug = window.location.href.includes('localhost');

export function getShapesToAdd() {
  let shapesToAdd = window.innerWidth <= 1024 && window.innerWidth < window.innerHeight ? initialShapesSmall : initialShapesLarge;
  return shapesToAdd;
}

function loadImage(url: string) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(img);
    img.onerror = reject;
    img.src = `${url}?v=${Date.now()}`;
  });
}

function loadAllImages(shapes: any[]) {
  const promises = shapes.map((shape) => loadImage(shape.url));
  const timerPromise = new Promise((resolve) => setTimeout(resolve, 5000)); // 5 seconds timer
  return Promise.all([...promises, timerPromise]);
}

function App() {
  const [isLoading, setIsLoading] = useState(true);

  // Whether or not the user can start making their card
  const [cardStarted, setCardStarted] = useState(false);

  // Whether or not the user selected the "start playing" button
  const [startPlaying, setStartPlaying] = useState(false);

  // Whether or not the intro is over
  const [isIntro, setIsIntro] = useState(false);

  // Whether or not the game has started
  const [introModalOpen, setIntroModalOpen] = useState(false);

  useEffect(() => {
    const shapesToAdd = getShapesToAdd();
    // Usage
    loadAllImages(shapesToAdd)
      .then(() => {
        // You can hide your loader here and show your images
        setIsLoading(false);
      })
      .catch((error) => {
        console.error('An image failed to load:', error);
      });
  }, []);

  const createCardInitialized = () => {
    setIntroModalOpen(true);
    setIsIntro(false);
  };

  return (
    <div className="App">
      <MainLoader isOpen={isLoading} />
      <IntroModal
        onClick={() => {
          setIntroModalOpen(false);
        }}
        isOpen={introModalOpen}
        animationComplete={() => {
          // If the loader is not showing and the card has not started then start the card
          if (!isLoading && !cardStarted) {
            setCardStarted(true);
          }
        }}
      />
      <AnimatePresence>
        {!startPlaying && !introModalOpen && (
          <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
            <StartButton
              onClick={() => {
                setIsIntro(true);
                setStartPlaying(true);
              }}
            />
          </motion.div>
        )}
      </AnimatePresence>
      <CardCanvas cardStarted={cardStarted} startPlaying={startPlaying} createCardInitialized={createCardInitialized} />
      <ItemLoader isOpen={isIntro} text="Create your own card!" topSmall="10px" />
    </div>
  );
}

export default App;
