// diamond-1-blue.svg
// diamond-1-chartreuse.svg
// diamond-1-green.svg
// diamond-1-orange.svg
// diamond-1-persimmon.svg
// diamond-1-pink.svg
// diamond-1-purple.svg
// diamond-1-violet.svg
// diamond-1-yellow.svg
// lightning-2--1.svg
// lightning-2--2.svg
// lightning-2--3.svg
// lightning-2--4.svg
// lightning-2-.svg
// lightning-2-green.svg
// lightning-2-pink.svg
// lightning-2-purple.svg
// lightning-2-violet.svg

// import { rawDataShape } from './CardCanvas';

export const additionalShapes = [
  {
    isCircle: false,
    name: 'diamond-1-blue',
    url: 'https://holiday-2023.s3.amazonaws.com/additional-shapes/diamond-1-blue.svg'
  },
  {
    isCircle: false,
    name: 'diamond-1-chartreuse',
    url: 'https://holiday-2023.s3.amazonaws.com/additional-shapes/diamond-1-chartreuse.svg'
  },
  {
    isCircle: false,
    name: 'diamond-1-green',
    url: 'https://holiday-2023.s3.amazonaws.com/additional-shapes/diamond-1-green.svg'
  },
  {
    isCircle: false,
    name: 'diamond-1-orange',
    url: 'https://holiday-2023.s3.amazonaws.com/additional-shapes/diamond-1-orange.svg'
  },
  {
    isCircle: false,
    name: 'diamond-1-persimmon',
    url: 'https://holiday-2023.s3.amazonaws.com/additional-shapes/diamond-1-persimmon.svg'
  },
  {
    isCircle: false,
    name: 'diamond-1-pink',
    url: 'https://holiday-2023.s3.amazonaws.com/additional-shapes/diamond-1-pink.svg'
  },
  {
    isCircle: false,
    name: 'diamond-1-purple',
    url: 'https://holiday-2023.s3.amazonaws.com/additional-shapes/diamond-1-purple.svg'
  },
  {
    isCircle: false,
    name: 'diamond-1-violet',
    url: 'https://holiday-2023.s3.amazonaws.com/additional-shapes/diamond-1-violet.svg'
  },
  {
    isCircle: false,
    name: 'diamond-1-yellow',
    url: 'https://holiday-2023.s3.amazonaws.com/additional-shapes/diamond-1-yellow.svg'
  },
  {
    isCircle: false,
    name: 'lightning-2--1',
    url: 'https://holiday-2023.s3.amazonaws.com/additional-shapes/lightning-2--1.svg'
  },
  {
    isCircle: false,
    name: 'lightning-2--2',
    url: 'https://holiday-2023.s3.amazonaws.com/additional-shapes/lightning-2--2.svg'
  },
  {
    isCircle: false,
    name: 'lightning-2--3',
    url: 'https://holiday-2023.s3.amazonaws.com/additional-shapes/lightning-2--3.svg'
  },
  {
    isCircle: false,
    name: 'lightning-2--4',
    url: 'https://holiday-2023.s3.amazonaws.com/additional-shapes/lightning-2--4.svg'
  },
  {
    isCircle: false,
    name: 'lightning-2-',
    url: 'https://holiday-2023.s3.amazonaws.com/additional-shapes/lightning-2-.svg'
  },
  {
    isCircle: false,
    name: 'lightning-2-green',
    url: 'https://holiday-2023.s3.amazonaws.com/additional-shapes/lightning-2-green.svg'
  },
  {
    isCircle: false,
    name: 'lightning-2-pink',
    url: 'https://holiday-2023.s3.amazonaws.com/additional-shapes/lightning-2-pink.svg'
  },
  {
    isCircle: false,
    name: 'lightning-2-purple',
    url: 'https://holiday-2023.s3.amazonaws.com/additional-shapes/lightning-2-purple.svg'
  },
  {
    isCircle: false,
    name: 'lightning-2-violet',
    url: 'https://holiday-2023.s3.amazonaws.com/additional-shapes/lightning-2-violet.svg'
  }
];
