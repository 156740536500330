export const initialShapesSmall = [
  {
    index: 0,
    isCircle: false,
    name: 'was07',
    url: 'https://holiday-2023.s3.amazonaws.com/words/initial-message/Was.svg',
    width: 55.01385967131889,
    height: 34.09309613433897,
    angle: -9.668409871589077,
    position: {
      x: 960.1649256951399,
      y: 361.87430262124747
    },
    scale: 38.7421546981119
  },
  {
    index: 1,
    isCircle: false,
    name: 'this05',
    url: 'https://holiday-2023.s3.amazonaws.com/words/initial-message/This.svg',
    width: 51.61678566271333,
    height: 31.543591238324964,
    angle: 9.376853963345845,
    position: {
      x: 908.4605389084969,
      y: 329.5883721149876
    },
    scale: 35.84499004355093
  },
  {
    index: 2,
    isCircle: false,
    name: 'year09',
    url: 'https://holiday-2023.s3.amazonaws.com/words/initial-message/Year.svg',
    width: 62.733192008522195,
    height: 35.06398915789642,
    angle: -2.0368413933830762,
    position: {
      x: 972.024275082714,
      y: 327.1874032104099
    },
    scale: 40.47302710227238
  },
  {
    index: 3,
    isCircle: false,
    name: 'excited00',
    url: 'https://holiday-2023.s3.amazonaws.com/words/initial-message/Excited.svg',
    width: 113.21986446172527,
    height: 43.698895055402645,
    angle: 6.025650272018885,
    position: {
      x: 843.5438190193667,
      y: 476.76176872931364
    },
    scale: 49.65783529023038
  },
  {
    index: 4,
    isCircle: false,
    name: 'huge01',
    url: 'https://holiday-2023.s3.amazonaws.com/words/initial-message/Hude.svg',
    width: 104.4149196452726,
    height: 57.07150887443512,
    angle: 9.42850200042073,
    position: {
      x: 910.6636046155279,
      y: 412.4559213408375
    },
    scale: 64.85398735731218
  },
  {
    index: 5,
    isCircle: false,
    name: 'to06',
    url: 'https://holiday-2023.s3.amazonaws.com/words/initial-message/To.svg',
    width: 41.287493642132304,
    height: 37.45669526296486,
    angle: 0.5220980923304178,
    position: {
      x: 963.1004182650504,
      y: 496.95739442876237
    },
    scale: 42.56442643518794
  },
  {
    index: 6,
    isCircle: false,
    name: 'you010',
    url: 'https://holiday-2023.s3.amazonaws.com/words/initial-message/You.svg',
    width: 84.40643391623168,
    height: 56.700505226171785,
    angle: -4.712492540008959,
    position: {
      x: 935.0293216485887,
      y: 684.3854734249121
    },
    scale: 64.43239230246694
  },
  {
    index: 7,
    isCircle: false,
    name: 'magic02',
    url: 'https://holiday-2023.s3.amazonaws.com/words/initial-message/Magic.svg',
    width: 117.13755557098995,
    height: 55.419918764769456,
    angle: -12.200165283330852,
    position: {
      x: 933.174298449117,
      y: 570.401985550499
    },
    scale: 62.97718041451073
  },
  {
    index: 8,
    isCircle: false,
    name: 'make03',
    url: 'https://holiday-2023.s3.amazonaws.com/words/initial-message/Make.svg',
    width: 68.34610985156381,
    height: 36.451258587500746,
    angle: -9.834250055230374,
    position: {
      x: 1016.9540857905641,
      y: 490.6258905245529
    },
    scale: 41.42188475852352
  },
  {
    index: 9,
    isCircle: false,
    name: 'more04',
    url: 'https://holiday-2023.s3.amazonaws.com/words/initial-message/More.svg',
    width: 84.96506167201612,
    height: 44.242162290753804,
    angle: -1.051764949794451,
    position: {
      x: 882.523299104889,
      y: 540.8097782739279
    },
    scale: 50.275184421311316
  },
  {
    index: 10,
    isCircle: false,
    name: 'with08',
    url: 'https://holiday-2023.s3.amazonaws.com/words/initial-message/With.svg',
    width: 72.39761800392058,
    height: 39.47827271296058,
    angle: 3.896674628258096,
    position: {
      x: 901.3321893784148,
      y: 643.6503689380396
    },
    scale: 45.24851125245036
  },
  {
    index: 11,
    isCircle: false,
    name: 'LC1110',
    url: 'https://holiday-2023.s3.amazonaws.com/stickers/LC.svg',
    width: 102.90440923077966,
    height: 121.30072819941145,
    angle: -2.2469430963951686,
    position: {
      x: 917.8942765020582,
      y: 780.2159905525386
    },
    scale: 57.48849677697188
  },
  {
    index: 12,
    isCircle: false,
    name: 'countdown113',
    url: 'https://holiday-2023.s3.amazonaws.com/stickers/countdown.svg',
    width: 105.27698782963817,
    height: 113.28050152429006,
    angle: -12.839971665284125,
    position: {
      x: 786.9190022201229,
      y: 300.1214269013286
    },
    scale: 61.56548995885274
  },
  {
    index: 13,
    isCircle: false,
    name: '2024-glasses110',
    url: 'https://holiday-2023.s3.amazonaws.com/stickers/2024-glasses.svg',
    width: 102.41176445084571,
    height: 75.6179888677736,
    angle: 0.4697924606505207,
    position: {
      x: 821.7398306538076,
      y: 598.9073756895098
    },
    scale: 59.541723517933555
  },
  {
    index: 14,
    isCircle: false,
    name: 'Wow1116',
    url: 'https://holiday-2023.s3.amazonaws.com/stickers/Wow.svg',
    width: 96.34840145562076,
    height: 39.368164035629825,
    angle: 25.27666273701738,
    position: {
      x: 1012.1734793049925,
      y: 297.55118716572264
    },
    scale: 51.80021583635524
  },
  {
    index: 15,
    isCircle: false,
    name: 'cheers112',
    url: 'https://holiday-2023.s3.amazonaws.com/stickers/cheers.svg',
    width: 119.99995377644764,
    height: 40.909075151061764,
    angle: 9.84734522283438,
    position: {
      x: 780.6686104666409,
      y: 676.456448670549
    },
    scale: 54.54543353474893
  },
  {
    index: 16,
    isCircle: false,
    name: 'thank-you1115',
    url: 'https://holiday-2023.s3.amazonaws.com/stickers/thank-you.svg',
    width: 131.254558192425,
    height: 86.8780170892723,
    angle: 2.847674988801956,
    position: {
      x: 795.4430068754338,
      y: 735.3542545720231
    },
    scale: 62.50217056782144
  },
  {
    index: 17,
    isCircle: false,
    name: 'peace-hand1112',
    url: 'https://holiday-2023.s3.amazonaws.com/stickers/peace-hand.svg',
    width: 53.36069754104884,
    height: 70.51235032210106,
    angle: 4.325076060910009,
    position: {
      x: 1027.846274963879,
      y: 371.7553776393814
    },
    scale: 63.52463992982005
  },
  {
    index: 18,
    isCircle: false,
    name: 'Yay1117',
    url: 'https://holiday-2023.s3.amazonaws.com/stickers/Yay.svg',
    width: 88.44387682540662,
    height: 48.3611452288946,
    angle: -21.3476946933901,
    position: {
      x: 800.3919220632167,
      y: 427.2037989409207
    },
    scale: 61.84886491287176
  },
  {
    index: 19,
    isCircle: false,
    name: 'heart-violet119',
    url: 'https://holiday-2023.s3.amazonaws.com/stickers/heart-violet.svg',
    width: 68.49667527335941,
    height: 63.327114875370256,
    angle: -15.346373082556122,
    position: {
      x: 797.6634507790137,
      y: 528.1441459088785
    },
    scale: 64.61950497486737
  },
  {
    index: 20,
    isCircle: false,
    name: 'heart-green117',
    url: 'https://holiday-2023.s3.amazonaws.com/stickers/heart-green.svg',
    width: 58.389481197034584,
    height: 53.982727899145246,
    angle: -3.236879397297933,
    position: {
      x: 1061.143217194652,
      y: 447.4143648738818
    },
    scale: 55.084416223617524
  },
  {
    index: 21,
    isCircle: false,
    name: 'DreamTeam114',
    url: 'https://holiday-2023.s3.amazonaws.com/stickers/DreamTeam.svg',
    width: 96.17587112882967,
    height: 70.683471552514,
    angle: 7.119790083555987,
    position: {
      x: 1006.624658550554,
      y: 630.1324300581728
    },
    scale: 57.93727176435522
  },
  {
    index: 22,
    isCircle: true,
    name: 'happy-new-year115',
    url: 'https://holiday-2023.s3.amazonaws.com/stickers/happy-new-year.svg',
    width: 103.78569126991103,
    height: 103.78569126991233,
    angle: 9.98626221175281,
    position: {
      x: 1025.488395810084,
      y: 729.2400984964983
    },
    scale: 54.33805825649792
  },
  {
    index: 23,
    isCircle: false,
    name: 'diamond-1-purple236',
    url: 'https://holiday-2023.s3.amazonaws.com/additional-shapes/diamond-1-purple.svg',
    width: 29.964241906507198,
    height: 35.26059537269432,
    angle: 11.818494418402697,
    position: {
      x: 1050.8542962117688,
      y: 536.4178225384885
    },
    scale: 34.44165736380138
  },
  {
    index: 24,
    isCircle: false,
    name: 'lightning-2--22310',
    url: 'https://holiday-2023.s3.amazonaws.com/additional-shapes/lightning-2--2.svg',
    width: 39.18874444675552,
    height: 56.054786360549194,
    angle: 13.873474378260171,
    position: {
      x: 1077.6570778967955,
      y: 563.3581939584536
    },
    scale: 49.60600562880446
  },
  {
    index: 25,
    isCircle: false,
    name: 'lightning-2-2313',
    url: 'https://holiday-2023.s3.amazonaws.com/additional-shapes/lightning-2-.svg',
    width: 39.280275627056504,
    height: 56.18571070705674,
    angle: 2.7600606676432475,
    position: {
      x: 886.2868153293622,
      y: 275.899839999878
    },
    scale: 49.721867882350004
  }
];
