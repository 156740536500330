import React, { useEffect, useRef, useState, FC } from 'react';
import { Image, Stage, Layer, Rect, Transformer } from 'react-konva';
import Konva from 'konva';
import Shape from './Shape';
import useImage from 'use-image';

interface KonvaStageProps {
  stageRef: any;
  selectionRectRef: any;
  stageDimensions: any;
  isFalling: boolean;
  konvaShapes: any[];
  setKonvaShapes: any;
  selectedShapes: string[];
  setSelectedShapes: any;
  updateMatterShape: any;
  deleteSelectedShapes: any;
  backgroundImage: string;
}

export const KonvaStage: FC<KonvaStageProps> = ({
  stageRef,
  stageDimensions,
  isFalling,
  konvaShapes,
  setKonvaShapes,
  selectedShapes,
  setSelectedShapes,
  updateMatterShape,
  deleteSelectedShapes,
  backgroundImage
}) => {
  const bgImage = backgroundImage ? `${backgroundImage}?stopit=true` : '';
  const [image] = useImage(bgImage, 'anonymous');

  // Konva Rect
  const rectRef = useRef<any>(null);
  // Konva Transformer
  const trRef = useRef<any>(null);
  const layerRef = useRef<any>(null);

  const selectionRectRef = useRef<any>(null);
  const selection = useRef({
    visible: false,
    x1: 0,
    y1: 0,
    x2: 0,
    y2: 0
  });

  const isShapeMoved = useRef(false); // New ref to track if a shape is moved
  const [imageDimensions, setImageDimensions] = useState({ width: 0, height: 0, x: 0, y: 0 });

  useEffect(() => {
    if (isFalling) {
      setSelectedShapes([]);
    }
  }, [isFalling, setSelectedShapes]);

  useEffect(() => {
    if (image) {
      const stageRatio = stageDimensions.width / stageDimensions.height;
      const imageRatio = image.width / image.height;

      let width, height;
      if (stageRatio > imageRatio) {
        // Stage is wider than the image
        width = stageDimensions.width;
        height = width / imageRatio;
      } else {
        // Stage is taller than the image
        height = stageDimensions.height;
        width = height * imageRatio;
      }

      const x = (stageDimensions.width - width) / 2;
      const y = (stageDimensions.height - height) / 2;

      setImageDimensions({ width, height, x, y });
    }
  }, [image, stageDimensions]);

  useEffect(() => {
    const nodes = selectedShapes.map((id) => layerRef.current.findOne('#' + id));
    trRef.current.nodes(nodes);
  }, [selectedShapes]);

  const checkDeselect = (e: any) => {
    // deselect when clicked on empty area
    const clickedOnEmpty = e.target === e.target.getStage() || e.target === rectRef.current;
    if (clickedOnEmpty) {
      setSelectedShapes([]);
    }
  };

  const updateSelectionRect = () => {
    if (isShapeMoved.current) return; // Don't update the selection rect if a shape is moved (to avoid the selection rect from being updated when a shape is moved)
    const node = selectionRectRef.current;
    node.setAttrs({
      visible: selection.current.visible,
      x: Math.min(selection.current.x1, selection.current.x2),
      y: Math.min(selection.current.y1, selection.current.y2),
      width: Math.abs(selection.current.x1 - selection.current.x2),
      height: Math.abs(selection.current.y1 - selection.current.y2),
      fill: 'rgba(0, 161, 255, 0.3)'
    });
    node.getLayer().batchDraw();
  };

  const oldPos = React.useRef(null);
  const onMouseDown = (e: any) => {
    const isElement = e.target.findAncestor('.elements-container');
    const isTransformer = e.target.findAncestor('Transformer');
    if (isElement || isTransformer) {
      return;
    }

    const pos = e.target.getStage().getPointerPosition();
    selection.current.visible = true;
    selection.current.x1 = pos.x;
    selection.current.y1 = pos.y;
    selection.current.x2 = pos.x;
    selection.current.y2 = pos.y;
    updateSelectionRect();

    if (!e.target.hasName('rectangle')) {
      isShapeMoved.current = false; // Reset the flag
    }
  };

  const onMouseMove = (e: any) => {
    if (!selection.current.visible) {
      return;
    }
    const pos = e.target.getStage().getPointerPosition();
    selection.current.x2 = pos.x;
    selection.current.y2 = pos.y;
    updateSelectionRect();
  };

  const onMouseUp = (e: any) => {
    oldPos.current = null;
    selection.current.visible = false;
    const { x1, x2, y1, y2 } = selection.current;
    const moved = x1 !== x2 || y1 !== y2;
    if (!moved) {
      updateSelectionRect();

      const clickedOnEmpty = e.target === e.target.getStage() || e.target === rectRef.current;
      if (clickedOnEmpty) {
        setSelectedShapes([]);
      }

      return;
    }
    const selBox = selectionRectRef.current.getClientRect();
    if (selBox.width < 5 || selBox.height < 5 || isShapeMoved.current) {
      updateSelectionRect();
      return;
    }

    const elements: any = [];
    layerRef.current.find('.rectangle').forEach((elementNode: any) => {
      const elBox = elementNode.getClientRect();
      if (Konva.Util.haveIntersection(selBox, elBox)) {
        elements.push(elementNode);
      }
    });
    // dont attempt to update if we already have a multiple selection
    if (selectedShapes.length > 1) return;
    setSelectedShapes(elements.map((el: any) => el.id()));
    updateSelectionRect();

    // Reset the movement flag
    isShapeMoved.current = false;
  };

  const onClickTap = (e: any) => {
    // if we are selecting with rect, do nothing
    const { x1, x2, y1, y2 } = selection.current;
    const moved = x1 !== x2 || y1 !== y2;
    if (moved) {
      return;
    }
    let stage = e.target.getStage();
    let layer = layerRef.current;
    let tr = trRef.current;
    // if click on empty area - remove all selections
    if (e.target === stage) {
      setSelectedShapes([]);
      return;
    }

    // do nothing if clicked NOT on our rectangles
    if (!e.target.hasName('rectangle')) {
      return;
    }

    // do we pressed shift or ctrl?
    const metaPressed = e.evt.shiftKey || e.evt.ctrlKey || e.evt.metaKey;
    const isSelected = tr.nodes().indexOf(e.target) >= 0;

    if (!metaPressed && !isSelected) {
      // if no key pressed and the node is not selected
      // select just one
      setSelectedShapes([e.target.id()]);
    } else if (metaPressed && isSelected) {
      // if we pressed keys and node was selected
      // we need to remove it from selection:
      setSelectedShapes((oldShapes: any) => {
        return oldShapes.filter((oldId: any) => oldId !== e.target.id());
      });
    } else if (metaPressed && !isSelected) {
      // add the node into selection
      setSelectedShapes((oldShapes: any) => {
        return [...oldShapes, e.target.id()];
      });
    }
    layer.draw();
  };

  /**
   * Takes in a shape id and brings it to the last position in the array
   * in order to restack the shapes
   */
  const reStackShapes = (id: any) => {
    // copy the array
    const shapes = [...konvaShapes];
    // find the shape with the id
    const shape = shapes.find((shape) => shape.id === id);
    if (!shape) return;
    // remove the shape from the array
    const index = shapes.indexOf(shape);
    shapes.splice(index, 1);
    // push the shape back onto the array
    shapes.push(shape);

    setKonvaShapes(shapes);
  };

  const handleShapeClick = (e: any, id: any) => {
    isShapeMoved.current = true;

    if (!selectedShapes.includes(id)) {
      setSelectedShapes([id]);
    }
  };

  // const handleShapeClick = (e: any, id: any) => {
  //   isShapeMoved.current = true; // Set the flag when a shape is clicked

  //   setSelectedShapes([id]);
  // };

  const updateShape = (i: any, newAttrs: any) => {
    const shapes = [...konvaShapes];
    shapes[i] = newAttrs;
    setKonvaShapes(shapes);
  };

  return (
    <Stage
      width={stageDimensions.width}
      height={stageDimensions.height}
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: !isFalling ? 10 : -1,
        opacity: !isFalling ? 1 : 0
      }}
      ref={stageRef}
      // onClick={(e) => (e.target === stageRef.current || e.target === rectRef.current) && setSelectedShapes([])}

      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onMouseMove={onMouseMove}
      onTouchStart={checkDeselect}
      onClick={onClickTap}
      onTap={onClickTap}
    >
      <Layer ref={layerRef}>
        {/* // Background */}
        <Image
          x={imageDimensions.x}
          y={imageDimensions.y}
          width={imageDimensions.width}
          height={imageDimensions.height}
          fill="#1d1d1d"
          image={image}
          draggable={false}
          ref={rectRef}
          opacity={0.75}
        />
        {/* <Rect
          x={0}
          y={0}
          width={stageDimensions.width}
          height={stageDimensions.height}
          fill="#1d1d1d"
          draggable={false}
          ref={rectRef}
        /> */}
        {konvaShapes.map((shape, i) => (
          <Shape
            key={shape.id}
            shapeProps={shape}
            isSelected={selectedShapes.includes(shape.id)}
            onSelect={(e) => handleShapeClick(e, shape.id)}
            onChange={(newAttrs) => updateShape(i, newAttrs)}
            width={shape.width}
            height={shape.height}
            bringToFront={reStackShapes}
            syncMatterShape={updateMatterShape}
            deleteShape={deleteSelectedShapes}
            isGroupSelection={selectedShapes.length > 1}
          />
        ))}

        <Transformer
          // ref={trRef.current[getKey]}
          ref={trRef}
          boundBoxFunc={(oldBox, newBox) => {
            // limit resize
            if (newBox.width < 5 || newBox.height < 5) {
              return oldBox;
            }
            return newBox;
          }}
          // anchorStroke="#fff"
          // anchorFill="rgba(0, 255, 195, 0.5)"
          // borderStroke="#ffffffad"

          // borderDash={[6, 6]}
          anchorSize={10}
          keepRatio={true}
          enabledAnchors={['top-left', 'top-right', 'bottom-left', 'bottom-right']}
        />
        <Rect fill="rgba(0, 132, 255, 0.5)" ref={selectionRectRef} />
      </Layer>
    </Stage>
  );
};
