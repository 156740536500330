import React, { useEffect, useRef, FC } from 'react';
import { Image, Transformer } from 'react-konva';
import Konva from 'konva';
import useImage from 'use-image';
import { clamp } from '../utils';

interface ShapeProps {
  shapeProps: Konva.ImageConfig;
  isSelected: boolean;
  onSelect: (e: any) => void;
  deleteShape: (e: any) => void;
  onChange: (newAttrs: Konva.ImageConfig) => void;
  width: number;
  height: number;
  bringToFront: any;
  syncMatterShape: any;
  isGroupSelection: boolean;
}

const Shape: FC<ShapeProps> = ({
  shapeProps,
  isSelected,
  onSelect,
  onChange,
  deleteShape,
  bringToFront,
  syncMatterShape,
  isGroupSelection
}) => {
  const shapeRef = useRef<Konva.Image>(null);
  const trRef = useRef<Konva.Transformer>(null);
  const [image] = useImage(shapeProps.url, 'anonymous');

  const isGroupSelectionRef = useRef(isGroupSelection);

  useEffect(() => {
    isGroupSelectionRef.current = isGroupSelection;
  }, [isGroupSelection]);

  useEffect(() => {
    if (isSelected) {
      trRef.current?.nodes([shapeRef.current!]);
      trRef.current?.getLayer()?.batchDraw();
    }
  }, [isSelected]);

  const handleDragEnd = (e: Konva.KonvaEventObject<DragEvent>) => {
    const newProps = {
      ...shapeProps,
      x: e.target.x(),
      y: e.target.y()
    };

    // const newPos = {
    //   x: e.target.x(),
    //   y: e.target.y()
    // };

    // const unscaledPosition = unscalePosition(shapeProps, newPos);
    // console.log('Name: ', shapeProps.id);
    // console.log('handleDragEnd', unscaledPosition);

    onChange(newProps);
    syncMatterShape(shapeProps.konvaId, newProps);
  };

  const handleTransformEnd = () => {
    const node = shapeRef.current!;
    const scaleX = node.scaleX();
    const scaleY = node.scaleY();

    const { width, scale } = shapeProps;

    if (!width || !scale) return;

    const newWidth = Math.max(5, node.width() * scaleX);
    const newHeight = Math.max(node.height() * scaleY);

    // Do I need this??
    // @ts-ignore
    const clampedScale = clamp(1, 10000, (newWidth / width) * scale);

    // we will reset it back
    node.scaleX(1);
    node.scaleY(1);
    const newProps = {
      ...shapeProps,
      x: node.x(),
      y: node.y(),
      // set minimal value
      width: newWidth,
      height: newHeight,
      angle: node.rotation(),

      scale: clampedScale
    };
    // @ts-ignore
    onChange(newProps);
    syncMatterShape(shapeProps.konvaId, newProps);
  };

  const handleDragStart = (e: Konva.KonvaEventObject<DragEvent>) => {
    // console.log('🚀 ~ file: Shape.tsx:100 ~ handleDragStart ~ isGroupSelection:', isGroupSelection);
    if (isGroupSelectionRef.current) return;
    onSelect(e);
  };

  const { id, x, y, width, height, angle } = shapeProps;

  return (
    <>
      <Image
        ref={shapeRef}
        id={id!.toString()}
        // name={id!.toString()}
        name="rectangle"
        image={image}
        x={x}
        y={y}
        width={width}
        height={height}
        offsetX={width! / 2}
        offsetY={height! / 2}
        rotation={angle}
        draggable
        scaleX={1} // Apply scaleX
        scaleY={1} // Apply scaleY
        onClick={(e) => {
          if (isGroupSelectionRef.current) return;
          bringToFront(id);
          onSelect(e);
        }}
        onTap={(e) => {
          if (isGroupSelectionRef.current) return;
          bringToFront(id);
          onSelect(e);
        }}
        onDragEnd={(e) => {
          // if (isGroupSelectionRef.current) return;
          handleDragEnd(e);
        }}
        onDragStart={(e) => {
          if (isGroupSelectionRef.current) return;
          bringToFront(id);
          handleDragStart(e);
        }}
        onTransformEnd={handleTransformEnd}
        // Delete on double click
        onDblClick={deleteShape}
        onDblTap={deleteShape}
      />
      {isSelected && (
        <Transformer
          ref={trRef}
          // anchorStroke="#fff"
          // anchorFill="rgba(255, 0, 179, 0.5)"
          // borderStroke="#ffffffad"
          // borderDash={[3, 3]}
          anchorSize={8}
          keepRatio={true}
          enabledAnchors={['top-left', 'top-right', 'bottom-left', 'bottom-right']}
        />
      )}
    </>
  );
};

export default Shape;
