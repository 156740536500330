import styled from '@emotion/styled';
import { motion } from 'framer-motion';

export const ControlPanel = styled(motion.div)`
  font-size: 16px;
  /* :root {
    --switch-width: 2.5em;
    --switch-height: calc(var(--switch-width) / 2);
  } */

  position: absolute;
  top: 1em;
  right: 1em;
  background-color: #1d1d1df7;

  font: 0.75em/1.25em 'Inter', Arial, Helvetica, sans-serif;
  font-weight: 400;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
  z-index: 1;
  border-radius: 1.75em;
  border: 1px solid #f1f1f133;
  padding: 0.75em 1.5em;
  z-index: 99;
  width: 18.25em;
  color: #f1f1f1;

  border-radius: 3px;
  background: var(--Black, #000000be);
  box-shadow: 10px 5px 40px 0px rgba(0, 0, 0, 0.7);

  @media (max-width: 768px) {
    width: 100vw;
    top: 0;
    right: 0;
    border-radius: 0;
    box-sizing: border-box;
    font-size: 14px;
    border: none;
  }

  @media (max-height: 1024px) {
    font-size: 12px;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  --switch-width: 2.5em;
  --switch-height: calc(var(--switch-width) / 2);

  .header-switch {
    display: flex;
    align-items: center;
    gap: 0.5em;
  }

  h1 {
    font-weight: 400;
    font-size: 1.125rem;
  }

  .switch {
    width: var(--switch-width);
    height: var(--switch-height);
    background: var(--play-mode-color);
    display: flex;
    justify-content: flex-start;
    border-radius: 3.125em;
    padding: calc(var(--switch-height) / 4);
    cursor: pointer;
  }

  .switch[data-ison='true'] {
    justify-content: flex-end;
    background: var(--edit-mode-color);
  }

  .switch[data-isdisabled='true'] {
    background: rgba(255, 255, 255, 0.4);
    pointer-events: none;
    cursor: not-allowed;
  }

  .handle {
    width: var(--switch-height);
    height: var(--switch-height);
    background-color: black;
    border-radius: 50%;
  }

  @media (max-height: 1024px) {
    h1 {
      font-size: 1rem;
    }
  }
`;

export const Content = styled(motion.div)`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;

  @media (max-width: 768px) {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1em;
    > div {
      flex: 1 1 40%;
    }
  }
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;

  h2 {
    font-weight: 600;
    font-size: 1.25em;
    margin: 0;

    color: var(--White, #fff);
    font-family: Inter;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 1.125rem */
  }

  @media (max-height: 1024px) {
    font-size: 11px;

    h2 {
      font-size: 1.125em;
    }
  }
`;

export const Separator = styled.div`
  width: 100%;
  height: 1px;
  background-color: #e1e1e14e;
  margin: 0.35em 0;
`;

export const Button = styled.button`
  background-color: transparent;
  border-radius: 2em;

  text-align: left;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  justify-content: space-between;
  display: flex;
  align-items: center;

  border-radius: 1.25rem;
  border: 0.811px solid var(--White, #fff);
  box-shadow: 0px 4.834px 14.501px 0px rgba(29, 29, 29, 0.2);

  padding: 0.625rem 1.25rem;
  align-items: center;
  gap: 0.20138rem;
  align-self: stretch;
  user-select: none;
  color: var(--White, #fff);

  font-family: Inter;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 1.125rem */
  text-transform: capitalize;

  .subtext {
    font-size: 0.75em;
    font-weight: 400;
    color: #e1e1e1;
  }

  @media (hover: hover) {
    :hover:not(:disabled) {
      background: #fff;
      border-color: #fff;
      color: #000;
      transition: none;
    }
  }

  svg {
    width: 1rem;
    height: auto;
  }

  :disabled {
    cursor: not-allowed;
    opacity: 0.5;
    background: transparent;
  }
  position: relative;
  @media (max-height: 1024px) {
    font-size: 11px;
  }
`;

export const FunButton = styled(Button)`
  @media (hover: hover) {
    &:hover:not(:disabled) {
      background: var(--play-mode-color);
      border-color: var(--play-mode-color);
    }
  }

  @media (hover: none) {
    &:before {
      content: '';
      position: absolute;
      top: 50%;
      right: 10px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      transform: translateY(-50%);
      background: var(--play-mode-color);
    }

    &:active {
      background: var(--play-mode-color);
      border-color: var(--play-mode-color);
    }
  }

  :disabled {
    background: transparent;
  }
`;

export const EditButton = styled(FunButton)`
  @media (hover: hover) {
    :hover:not(:disabled) {
      background: var(--edit-mode-color);
      border-color: var(--edit-mode-color);
      transition: none;
    }
  }

  @media (hover: none) {
    &:before {
      background: var(--edit-mode-color);
      border-color: var(--edit-mode-color);
    }
    &:active {
      background: var(--edit-mode-color);
    }
  }

  :disabled {
    background: transparent;
  }
`;

export const DeleteButton = styled(Button)`
  border: 1px solid #f55a50;

  &:hover {
    background: linear-gradient(250deg, #f55a50, #f55a50);
  }

  :disabled {
    background: transparent;
    cursor: not-allowed;
  }
`;

export const Footer = styled(Section)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding: 0.5rem;

  svg {
    width: 150px;
    height: auto;
  }

  a:hover {
    svg path {
      fill: #9d6cff;
    }
  }
`;

export const TopFooter = styled.div`
  display: none;

  svg {
    width: 110px;
  }

  @media (max-width: 768px) {
    display: block;
  }
`;

export const BottomFooter = styled.div`
  display: block;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const MenuToggle = styled.button`
  background-color: #1d1d1d;
  font: 0.75em/1.25em 'Inter', Arial, Helvetica, sans-serif;
  font-weight: 400;
  z-index: 1;
  border-radius: 1.75em;
  /* border: 1px solid #f1f1f130; */
  border: none;
  padding: 0.25em 0.75em;
  z-index: 99;
  /* width: 100%; */
  color: #f1f1f1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1em;
  cursor: pointer;

  svg {
    width: 1.5em;
    height: auto;
    cursor: pointer;
  }

  &:hover {
    /* border: 1px solid #f1f1f1; */
    background: #f1f1f130;
  }

  font-size: 16px;

  @media (max-height: 1024px) {
    font-size: 12px;
  }
`;
// export const ControlPanel = styled(motion.div)`
//   font-size: 16px;
//   /* :root {
//     --switch-width: 2.5em;
//     --switch-height: calc(var(--switch-width) / 2);
//   } */

//   position: absolute;
//   top: 1em;
//   right: 1em;
//   background-color: #1d1d1df7;
//   border-radius: 3px;
//   font: 0.75em/1.25em 'Inter', Arial, Helvetica, sans-serif;
//   font-weight: 400;
//   box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
//   z-index: 1;
//   border-radius: 1.75em;
//   border: 1px solid #f1f1f170;
//   padding: 0.75em 1.5em;
//   z-index: 99;
//   width: 21.25em;
//   color: #f1f1f1;

//   @media (max-width: 768px) {
//     width: 100vw;
//     top: 0;
//     right: 0;
//     border-radius: 0;
//     box-sizing: border-box;
//     font-size: 14px;
//     border: none;
//   }

//   @media (max-height: 1024px) {
//     font-size: 12px;
//   }
// `;

// export const Header = styled.div`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   --switch-width: 2.5em;
//   --switch-height: calc(var(--switch-width) / 2);

//   .header-switch {
//     display: flex;
//     align-items: center;
//     gap: 0.5em;
//   }

//   h1 {
//     font-weight: 400;
//   }

//   .switch {
//     width: var(--switch-width);
//     height: var(--switch-height);
//     background: var(--play-mode-color);
//     display: flex;
//     justify-content: flex-start;
//     border-radius: 3.125em;
//     padding: calc(var(--switch-height) / 4);
//     cursor: pointer;
//   }

//   .switch[data-ison='true'] {
//     justify-content: flex-end;
//     background: var(--edit-mode-color);
//   }

//   .switch[data-isdisabled='true'] {
//     background: rgba(255, 255, 255, 0.4);
//     pointer-events: none;
//     cursor: not-allowed;
//   }

//   .handle {
//     width: var(--switch-height);
//     height: var(--switch-height);
//     background-color: white;
//     border-radius: 50%;
//   }

//   @media (max-height: 1024px) {
//     h1 {
//       font-size: 1rem;
//     }
//   }
// `;

// export const Content = styled(motion.div)`
//   margin-top: 1rem;
//   display: flex;
//   flex-direction: column;
//   gap: 1em;

//   @media (max-width: 768px) {
//     flex-direction: row;
//     flex-wrap: wrap;
//     > div {
//       flex: 1 1 40%;
//     }
//   }
// `;

// export const Section = styled.div`
//   display: flex;
//   flex-direction: column;
//   gap: 1em;

//   h2 {
//     font-weight: 600;
//     font-size: 1.25em;
//     margin: 0.5em 0;
//   }

//   @media (max-height: 1024px) {
//     font-size: 11px;

//     h2 {
//       font-size: 1.125em;
//     }
//   }
// `;

// export const Separator = styled.div`
//   width: 100%;
//   height: 1px;
//   background-color: #e1e1e14e;
//   margin: 0.35em 0;
// `;

// export const Button = styled.button`
//   background-color: transparent;
//   border: 1px solid #f1f1f170;
//   border-radius: 2em;
//   padding: 1em 1.75em;
//   color: #e1e1e1;
//   font-weight: 600;
//   text-align: left;
//   cursor: pointer;
//   transition: all 0.2s ease-in-out;
//   font-size: 1rem;
//   justify-content: space-between;
//   display: flex;
//   align-items: center;

//   .subtext {
//     font-size: 0.75em;
//     font-weight: 400;
//     color: #e1e1e1;
//   }

//   :hover {
//     background: var(--edit-mode-color);
//   }

//   svg {
//     width: 1rem;
//     height: auto;
//   }

//   :disabled {
//     cursor: not-allowed;
//     cursor: wait;
//     opacity: 0.5;
//     background: transparent;
//   }

//   @media (max-height: 1024px) {
//     font-size: 11px;
//   }
// `;

// export const FunButton = styled(Button)`
//   &:hover {
//     background: var(--play-mode-color);
//   }

//   :disabled {
//     background: transparent;
//   }
// `;

// export const DeleteButton = styled(Button)`
//   border: 1px solid #f55a50;

//   &:hover {
//     background: linear-gradient(250deg, #f55a50, #f55a50);
//   }

//   :disabled {
//     background: transparent;
//     cursor: not-allowed;
//   }
// `;

// export const Footer = styled(Section)`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   gap: 1rem;
//   padding: 0.5rem;

//   svg {
//     width: 150px;
//     height: auto;
//   }
// `;

// export const MenuToggle = styled.button`
//   background-color: #1d1d1d;
//   font: 0.75em/1.25em 'Inter', Arial, Helvetica, sans-serif;
//   font-weight: 400;
//   z-index: 1;
//   border-radius: 1.75em;
//   border: 1px solid #f1f1f130;
//   padding: 0.25em 0.75em;
//   z-index: 99;
//   /* width: 100%; */
//   color: #f1f1f1;
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   gap: 1em;
//   cursor: pointer;

//   svg {
//     width: 1.5em;
//     height: auto;
//     cursor: pointer;
//   }

//   &:hover {
//     border: 1px solid #f1f1f1;
//   }

//   font-size: 16px;

//   @media (max-height: 1024px) {
//     font-size: 12px;
//   }
// `;
