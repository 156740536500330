import styled from '@emotion/styled';
import { motion } from 'framer-motion';

export const MainLoader = styled(motion.div)`
  font-size: 16px;
  :root {
    --switch-width: 2.5em;
    --switch-height: calc(var(--switch-width) / 2);
  }

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #1d1d1d;
  border-radius: 3px;
  font: 0.75em/1.25em 'Inter', Arial, Helvetica, sans-serif;
  font-weight: 400;
  z-index: 1;
  border-radius: 0;
  padding: 0.75em 1.5em;
  z-index: 9999;
  color: #f1f1f1;

  flex-direction: column;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  box-sizing: border-box;

  img {
    width: 100%;
  }

  a {
    text-decoration: none;
    cusor: pointer;
  }
  a:hover {
    svg path {
      fill: #9d6cff;
    }
  }

  @media (max-height: 1024px) {
  }

  @media (max-width: 768px) {
    svg {
      width: 128px;
      height: auto;
    }
  }
`;

export const ImgContainer = styled.div`
  aspect-ratio: 533/648;
  width: 100%;
  max-width: 33rem;
  margin: 0 auto;

  border-radius: 2.5rem;
  border: 20px solid #373737;
  background: var(--BK, #000);
  overflow: hidden;
  box-sizing: border-box;

  @media (max-width: 768px) {
    max-width: 20.46338rem;
  }
`;

export const Loader = styled.div`
  .progress {
    background: #424242;
    justify-content: flex-start;
    border-radius: 100px;
    align-items: center;
    position: relative;
    padding: 0 5px;
    display: flex;
    width: 15rem;
    height: 0.5rem;
    overflow: hidden;
  }

  .progress-value {
    animation: load 0.75s normal linear infinite;
    box-shadow: 0 10px 40px -10px #fff;
    border-radius: 100px;
    background: #9d6cff;
    height: 100%;
    width: 100%;
  }

  @keyframes load {
    0% {
      transform: translateX(-110%);
    }
    50% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(110%);
    }
  }
`;

export const LoaderText = styled.p`
  color: var(--Caption, #9b9b9b);
  font-family: Inter;
  font-size: 1.04088rem;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 1.24906rem */
  text-align: center;
  margin: 0;
`;
