import Matter, { Composite, Engine, Render, World, Bodies, Runner } from 'matter-js';

export interface rawDataShape {
  isCircle: boolean;
  name: string;
  url: string;
}

interface ImageAsset {
  url: string;
  width: number;
  height: number;
}

interface Position {
  x: number;
  y: number;
}

interface Size {
  width: number;
  height: number;
}

export const matterJsBodyOptions = {
  label: 'shape',
  restitution: 0.4, // Adjust the bounciness
  friction: 0.8, // Adjust the friction
  frictionStatic: 0.5, // Adjust the static friction
  // inertia: Infinity, // Prevent too much rotation
  collisionFilter: {
    group: 0
  },
  slop: 0.05 // Make the collisions smoother
};

// @ts-ignore
export function clamp(value, min, max) {
  return Math.min(Math.max(value, min), max);
}

// Takes in a figma design position and scales it to the canvas size
// @ts-ignore
export function scalePosition(shape, canvasWidth, canvasHeight, designWidth = 1920, designHeight = 1080, position) {
  // Need to re-center the position from the figma position
  const newX = position.x + shape.width / 2;
  const newY = position.y + shape.height / 2;

  // Shift the position based on the canvas scale
  return {
    x: newX + (canvasWidth - designWidth) / 2,
    y: newY + (canvasHeight - designHeight) / 2
  };
}

// Takes in a Matter.js canvas position and scales it back to the Figma design position
// @ts-ignore
export function unscalePosition(shape, position) {
  const designWidth = 1920;
  const designHeight = 1080;
  // Undo the shift based on the canvas scale
  const adjustedX = position.x - (window.innerWidth - designWidth) / 2;
  const adjustedY = position.y - (window.innerHeight - designHeight) / 2;

  // Re-center the position to the Figma position
  const newX = adjustedX - shape.width / 2;
  const newY = adjustedY - shape.height / 2;

  return {
    x: newX,
    y: newY
  };
}

// @ts-ignore
export const doRectanglesOverlap = (rect1, rect2) => {
  return (
    rect1.x < rect2.x + rect2.width &&
    rect1.x + rect1.width > rect2.x &&
    rect1.y < rect2.y + rect2.height &&
    rect1.y + rect1.height > rect2.y
  );
};
// @ts-ignore
export function scaleImage(width, height, percentage) {
  // Calculate new width and height based on the percentage
  const newWidth = width * (percentage / 100);
  const newHeight = height * (percentage / 100);

  return {
    width: newWidth,
    height: newHeight
  };
}

export const loadAndSizeImage = (
  image: rawDataShape
): Promise<{ width: number; height: number; scale: { x: number; y: number } }> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = 'anonymous';
    img.src = `${image.url}?timestamp=${new Date()}`;
    img.setAttribute('crossOrigin', 'anonymous');

    img.onload = () => {
      let scaleFactor = 0.75; // Default scale factor

      // Adjust the scale factor based on the window width
      if (window.innerWidth <= 420) {
        scaleFactor = 0.35;
      } else if (window.innerWidth <= 1024) {
        scaleFactor = 0.5;
      } else if (window.innerWidth <= 1440) {
        scaleFactor = 0.5;
      } else if (window.innerWidth <= 1560) {
        scaleFactor = 0.5;
      }

      const scaledWidth = img.width * scaleFactor;
      const scaledHeight = img.height * scaleFactor;

      resolve({
        width: scaledWidth,
        height: scaledHeight,
        scale: { x: scaleFactor, y: scaleFactor }
      });
    };

    img.onerror = () => {
      reject(new Error('Failed to load image'));
    };
  });
};

export const loadSVGImage = (
  image: ImageAsset
): Promise<{ width: number; height: number; scale: { x: number; y: number } }> => {
  return new Promise((resolve, reject) => {
    let width = image.width;
    let height = image.height;

    // Create a new Image object
    const img = new Image();

    // Set CORS policy if necessary
    img.crossOrigin = 'anonymous';

    // Set the source of the image
    img.src = `${image.url}?timestamp=${new Date().getTime()}`;
    img.setAttribute('crossOrigin', 'anonymous');

    // Handle image loading
    img.onload = () => {
      // Initial scale
      const scale = {
        x: width / img.width,
        y: height / img.height
      };

      resolve({ width, height, scale });
    };

    // Handle image loading errors
    img.onerror = (error) => {
      reject(error);
    };
  });
};

export const findRandomPosition = (shapesToPosition: any[], size: Size): Position => {
  const { width, height } = size;

  // Find a random position for the image
  let collision = true;
  let retries = 0;
  let x, y;

  const defaultRandom = {
    x: Math.floor(Math.random() * (window.innerWidth - width * 2) + width),
    y: Math.floor(Math.random() * (window.innerHeight - height * 2) + height)
  };

  while (collision && retries < 1000) {
    x = Math.floor(Math.random() * (window.innerWidth - width * 2) + width);
    y = Math.floor(Math.random() * (window.innerHeight - height * 2) + height);

    collision = false;
    for (let existingShape of shapesToPosition) {
      if (
        doRectanglesOverlap(
          { x, y, width, height },
          {
            x: existingShape.body.position.x - existingShape.width / 2,
            y: existingShape.body.position.y - existingShape.height / 2,
            width: existingShape.width,
            height: existingShape.height
          }
        )
      ) {
        collision = true;
        retries++;
        break;
      }
    }
  }

  if (!collision) {
    if (!x || !y) return defaultRandom;
    return { x, y };
  }

  return defaultRandom;
};

export const waitForBodiesToSettle = (engine: any, maxTime: number = 3000, callback: () => void) => {
  let settledOverridden = false;
  let overrideTimeout: any = null;

  // Wait 5 seconds for the bodies to settle
  // setTimeout(() => {
  // Modify the interval to check if bodies are sleeping (settled)
  const interval = setInterval(() => {
    const settled = engine?.world.bodies.every((body: any) => {
      if (settledOverridden) {
        return true;
      }
      if (body.label === 'shape') {
        // console.log('💤', body.isSleeping);
        return body.isSleeping;
      }
      return true;
    });

    if (settled) {
      clearInterval(interval);
      console.log('All settled 🛑🛑🛑🛑🛑🛑🛑🛑🛑🛑🛑');

      // all bodies are settled add the react-konva shapes to resize
      callback();
      return;
    }

    if (!overrideTimeout) {
      overrideTimeout = setTimeout(() => {
        settledOverridden = true;
        console.log('📣📣📣📣📣📣', settledOverridden);
      }, maxTime);
    }
  }, 1000 / 60);
  // }, 5000);
};

export const setupMatterJs = (): any => {
  const matterContainer = document.getElementById('canvasContainer');
  const width = matterContainer?.clientWidth || window.innerWidth;
  const height = matterContainer?.clientHeight || window.innerHeight;

  const THICCNESS = 6000;
  if (!matterContainer) {
    return;
  }
  const eng = Engine.create({
    enableSleeping: true
  });

  const render = Render.create({
    element: matterContainer,
    engine: eng,
    options: {
      width: width,
      height: height,
      // showAngleIndicator: true,
      wireframes: false, // Disable wireframes here
      showSleeping: false,
      background: 'transparent'
    }
  });

  const ground = Bodies.rectangle(width / 2, height + THICCNESS / 2, 27184, THICCNESS, {
    isStatic: true,
    label: 'ground'
  });

  const topWall = Bodies.rectangle(width / 2, 0 - THICCNESS / 2, width * 5, THICCNESS, {
    isStatic: true,
    label: 'topWall'
  });

  const leftWall = Bodies.rectangle(0 - THICCNESS / 2, height / 2, THICCNESS, height * 5, {
    isStatic: true,
    label: 'leftWall'
  });

  const rightWall = Bodies.rectangle(width + THICCNESS / 2, height / 2, THICCNESS, height * 5, {
    isStatic: true,
    label: 'rightWall'
  });

  Composite.add(eng.world, [ground, leftWall, rightWall, topWall]);

  // Mouse Events
  let mouse = Matter.Mouse.create(render.canvas);
  let mouseConstraint = Matter.MouseConstraint.create(eng, {
    mouse: mouse,
    constraint: {
      stiffness: 0.2,
      render: {
        visible: false
      }
    }
  });
  Composite.add(eng.world, mouseConstraint);
  // allow scroll through the canvas
  // @ts-ignore
  mouseConstraint.mouse.element.removeEventListener('mousewheel', mouseConstraint.mouse!.mousewheel);
  // @ts-ignore
  mouseConstraint.mouse.element.removeEventListener('DOMMouseScroll', mouseConstraint.mouse!.mousewheel);

  Render.run(render);

  const runner = Runner.create();
  Runner.run(runner, eng);

  const updateDimensions = () => {
    const width = matterContainer?.clientWidth || window.innerWidth;
    const height = matterContainer?.clientHeight || window.innerHeight;

    // Set canvas size to new values
    render.canvas.width = width;
    render.canvas.height = height;

    // reposition ground
    Matter.Body.setPosition(ground, Matter.Vector.create(width / 2, height + THICCNESS / 2));
    // reposition right wall
    Matter.Body.setPosition(rightWall, Matter.Vector.create(width + THICCNESS / 2, height / 2));
    // Render.setPixelRatio(render, 'auto');
  };

  return {
    engine: eng,
    render,
    runner,
    updateDimensions
  };
};

export const removeMatterJs = (engine: any, render: any, runner: any) => {
  World.clear(engine.world, false);
  Engine.clear(engine);
  Render.stop(render);
  Runner.stop(runner);
  render.canvas.remove();
  render.canvas = null;
  render.context = null;
  render.textures = {};
};

export const removeQueryString = (urlString: string): string => {
  let url = new URL(urlString);
  url.search = ''; // Clears the query string
  return url.toString();
};
