// Excited.svg
// Hude.svg
// Magic.svg
// Make.svg
// More.svg
// This.svg
// To.svg
// Was.svg
// With.svg
// Year.svg
// You.svg

export const initialWords = [
  {
    name: 'excited',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/initial-message/Excited.svg'
  },
  {
    name: 'hude',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/initial-message/Hude.svg'
  },
  {
    name: 'magic',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/initial-message/Magic.svg'
  },
  {
    name: 'make',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/initial-message/Make.svg'
  },
  {
    name: 'more',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/initial-message/More.svg'
  },
  {
    name: 'this',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/initial-message/This.svg'
  },
  {
    name: 'to',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/initial-message/To.svg'
  },
  {
    name: 'was',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/initial-message/Was.svg'
  },
  {
    name: 'with',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/initial-message/With.svg'
  },
  {
    name: 'year',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/initial-message/Year.svg'
  },
  {
    name: 'you',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/initial-message/You.svg'
  }
];

// amazing.svg
// best.svg
// enjoy.svg
// forward.svg
// great.svg
// joy.svg
// light.svg
// looking.svg
// magic.svg
// make.svg
// many.svg
// moon.svg
// more.svg
// next.svg
// partners.svg
// sweet.svg
// wish.svg
// work.svg

export const words1 = [
  {
    name: 'amazing',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/words1/amazing.svg'
  },
  {
    name: 'best',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/words1/best.svg'
  },
  {
    name: 'enjoy',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/words1/enjoy.svg'
  },
  {
    name: 'forward',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/words1/forward.svg'
  },
  {
    name: 'great',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/words1/great.svg'
  },
  {
    name: 'joy',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/words1/joy.svg'
  },
  {
    name: 'light',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/words1/light.svg'
  },
  {
    name: 'looking',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/words1/looking.svg'
  },
  {
    name: 'magic',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/words1/magic.svg'
  },
  {
    name: 'make',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/words1/make.svg'
  },
  {
    name: 'many',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/words1/many.svg'
  },
  {
    name: 'moon',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/words1/moon.svg'
  },
  {
    name: 'more',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/words1/more.svg'
  },
  {
    name: 'next',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/words1/next.svg'
  },
  {
    name: 'partners',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/words1/partners.svg'
  },
  {
    name: 'sweet',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/words1/sweet.svg'
  },
  {
    name: 'wish',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/words1/wish.svg'
  },
  {
    name: 'work',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/words1/work.svg'
  }
];

// a.svg
// all.svg
// are.svg
// at.svg
// be.svg
// does.svg
// for.svg
// i.svg
// in.svg
// it.svg
// not.svg
// of.svg
// off.svg
// or.svg
// the.svg
// up.svg
// will.svg
// with.svg

export const words2 = [
  {
    name: 'a',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/words2/a.svg'
  },
  {
    name: 'all',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/words2/all.svg'
  },
  {
    name: 'to',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/words2/to.svg'
  },
  {
    name: 'are',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/words2/are.svg'
  },
  {
    name: 'at',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/words2/at.svg'
  },
  {
    name: 'be',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/words2/be.svg'
  },
  {
    name: 'does',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/words2/does.svg'
  },
  {
    name: 'for',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/words2/for.svg'
  },
  {
    name: 'i',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/words2/i.svg'
  },
  {
    name: 'in',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/words2/in.svg'
  },
  {
    name: 'it',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/words2/it.svg'
  },
  {
    name: 'not',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/words2/not.svg'
  },
  {
    name: 'of',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/words2/of.svg'
  },
  {
    name: 'off',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/words2/off.svg'
  },
  {
    name: 'or',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/words2/or.svg'
  },
  {
    name: 'the',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/words2/the.svg'
  },
  {
    name: 'up',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/words2/up.svg'
  },
  {
    name: 'will',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/words2/will.svg'
  },
  {
    name: 'with',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/words2/with.svg'
  }
];

// a.svg
// amp.svg
// apostrophes.svg
// as.svg
// ask.svg
// at.svg
// but.svg
// d.svg
// dollarsign.svg
// ed.svg
// er.svg
// es.svg
// exclamation.svg
// has.svg
// hash.svg
// if.svg
// ing.svg
// is.svg
// ly.svg
// me.svg
// my.svg
// on.svg
// question.svg
// s.svg
// there.svg
// these.svg
// under.svg
// was.svg
// were.svg
// y.svg

export const words3 = [
  {
    name: 'a',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/words3/a.svg'
  },
  {
    name: 'amp',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/words3/amp.svg'
  },
  {
    name: 'apostrophes',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/words3/apostrophes.svg'
  },
  {
    name: 'as',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/words3/as.svg'
  },
  {
    name: 'ask',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/words3/ask.svg'
  },
  {
    name: 'at',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/words3/at.svg'
  },
  {
    name: 'but',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/words3/but.svg'
  },
  {
    name: 'd',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/words3/d.svg'
  },
  {
    name: 'dollarsign',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/words3/dollarsign.svg'
  },
  {
    name: 'ed',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/words3/ed.svg'
  },
  {
    name: 'er',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/words3/er.svg'
  },
  {
    name: 'es',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/words3/es.svg'
  },
  {
    name: 'exclamation',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/words3/exclamation.svg'
  },
  {
    name: 'has',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/words3/has.svg'
  },
  {
    name: 'hash',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/words3/hash.svg'
  },
  {
    name: 'if',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/words3/if.svg'
  },
  {
    name: 'ing',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/words3/ing.svg'
  },
  {
    name: 'is',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/words3/is.svg'
  },
  {
    name: 'ly',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/words3/ly.svg'
  },
  {
    name: 'me',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/words3/me.svg'
  },
  {
    name: 'my',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/words3/my.svg'
  },
  {
    name: 'on',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/words3/on.svg'
  },
  {
    name: 'question',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/words3/question.svg'
  },
  {
    name: 's',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/words3/s.svg'
  },
  {
    name: 'there',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/words3/there.svg'
  },
  {
    name: 'these',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/words3/these.svg'
  },
  {
    name: 'under',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/words3/under.svg'
  },
  {
    name: 'was',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/words3/was.svg'
  },
  {
    name: 'were',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/words3/were.svg'
  },
  {
    name: 'y',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/words3/y.svg'
  }
];

// always.svg
// dance.svg
// heart.svg
// limit.svg
// love.svg
// moon.svg
// more.svg
// never.svg
// no.svg
// sky.svg
// song.svg
// soul.svg
// stars.svg
// sun.svg
// time.svg

export const words4 = [
  {
    name: 'always',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/words4/always.svg'
  },
  {
    name: 'dance',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/words4/dance.svg'
  },
  {
    name: 'heart',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/words4/heart.svg'
  },
  {
    name: 'limit',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/words4/limit.svg'
  },
  {
    name: 'love',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/words4/love.svg'
  },
  {
    name: 'moon',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/words4/moon.svg'
  },
  {
    name: 'more',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/words4/more.svg'
  },
  {
    name: 'never',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/words4/never.svg'
  },
  {
    name: 'no',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/words4/no.svg'
  },
  {
    name: 'sky',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/words4/sky.svg'
  },
  {
    name: 'song',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/words4/song.svg'
  },
  {
    name: 'soul',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/words4/soul.svg'
  },
  {
    name: 'stars',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/words4/stars.svg'
  },
  {
    name: 'sun',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/words4/sun.svg'
  },
  {
    name: 'time',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/words4/time.svg'
  }
];

// dream.svg
// earth.svg
// fire.svg
// hot.svg
// mirror.svg
// ocean.svg
// rain.svg
// shadow.svg
// silence.svg
// whisper.svg
// wind.svg
export const words5 = [
  {
    name: 'dream',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/words5/dream.svg'
  },
  {
    name: 'earth',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/words5/earth.svg'
  },
  {
    name: 'fire',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/words5/fire.svg'
  },
  {
    name: 'hot',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/words5/hot.svg'
  },
  {
    name: 'mirror',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/words5/mirror.svg'
  },
  {
    name: 'ocean',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/words5/ocean.svg'
  },
  {
    name: 'rain',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/words5/rain.svg'
  },
  {
    name: 'shadow',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/words5/shadow.svg'
  },
  {
    name: 'silence',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/words5/silence.svg'
  },
  {
    name: 'whisper',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/words5/whisper.svg'
  },
  {
    name: 'wind',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/words5/wind.svg'
  }
];

// beginning.svg
// celebration.svg
// cheer.svg
// end.svg
// glimmer.svg
// hope.svg
// inspiration.svg
// laughter.svg

export const words6 = [
  {
    name: 'beginning',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/words6/beginning.svg'
  },
  {
    name: 'celebration',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/words6/celebration.svg'
  },
  {
    name: 'cheer',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/words6/cheer.svg'
  },
  {
    name: 'end',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/words6/end.svg'
  },
  {
    name: 'glimmer',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/words6/glimmer.svg'
  },
  {
    name: 'hope',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/words6/hope.svg'
  },
  {
    name: 'inspiration',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/words6/inspiration.svg'
  },
  {
    name: 'laughter',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/words6/laughter.svg'
  }
];

// beautiful.svg
// cry.svg
// juice.svg
// laugh.svg
// movie.svg
// play.svg
// sparkle.svg
// thrill.svg
// tv.svg

export const words7 = [
  {
    name: 'beautiful',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/words7/beautiful.svg'
  },
  {
    name: 'cry',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/words7/cry.svg'
  },
  {
    name: 'juice',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/words7/juice.svg'
  },
  {
    name: 'laugh',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/words7/laugh.svg'
  },
  {
    name: 'movie',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/words7/movie.svg'
  },
  {
    name: 'play',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/words7/play.svg'
  },
  {
    name: 'sparkle',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/words7/sparkle.svg'
  },
  {
    name: 'thrill',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/words7/thrill.svg'
  },
  {
    name: 'tv',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/words7/tv.svg'
  }
];

// cinema.svg
// digital.svg
// fast.svg
// happy.svg
// life.svg
// little.svg
// run.svg
// stop.svg
// want.svg
// watch.svg

export const words8 = [
  {
    name: 'cinema',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/words8/cinema.svg'
  },
  {
    name: 'digital',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/words8/digital.svg'
  },
  {
    name: 'fast',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/words8/fast.svg'
  },
  {
    name: 'happy',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/words8/happy.svg'
  },
  {
    name: 'life',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/words8/life.svg'
  },
  {
    name: 'little',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/words8/little.svg'
  },
  {
    name: 'run',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/words8/run.svg'
  },
  {
    name: 'stop',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/words8/stop.svg'
  },
  {
    name: 'want',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/words8/want.svg'
  },
  {
    name: 'watch',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/words/words8/watch.svg'
  }
];
