// 2024-glasses.svg
// Buzz.svg
// cheers.svg
// countdown.svg
// DreamTeam.svg
// happy-new-year.svg
// heart-eyes.svg
// heart-green.svg
// heart-pixel.svg
// heart-violet.svg
// LC.svg
// Love.svg
// peace-hand.svg
// Really.svg
// smiley.svg
// thank-you.svg
// Wow.svgd
// Yay.svg

export const stickers = [
  {
    name: '2024-glasses',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/stickers/2024-glasses.svg'
  },
  {
    name: 'Buzz',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/stickers/Buzz.svg'
  },
  {
    name: 'cheers',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/stickers/cheers.svg'
  },
  {
    name: 'countdown',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/stickers/countdown.svg'
  },
  {
    name: 'DreamTeam',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/stickers/DreamTeam.svg'
  },
  {
    name: 'happy-new-year',
    isCircle: true,
    url: 'https://holiday-2023.s3.amazonaws.com/stickers/happy-new-year.svg'
  },
  {
    name: 'heart-eyes',
    isCircle: true,
    url: 'https://holiday-2023.s3.amazonaws.com/stickers/heart-eyes.svg'
  },
  {
    name: 'heart-green',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/stickers/heart-green.svg'
  },
  {
    name: 'heart-pixel',
    isCircle: true,
    url: 'https://holiday-2023.s3.amazonaws.com/stickers/heart-pixel.svg'
  },
  {
    name: 'heart-violet',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/stickers/heart-violet.svg'
  },
  {
    name: 'LC',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/stickers/LC.svg'
  },
  {
    name: 'Love',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/stickers/Love.svg'
  },
  {
    name: 'peace-hand',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/stickers/peace-hand.svg'
  },
  {
    name: 'Really',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/stickers/Really.svg'
  },
  {
    name: 'smiley',
    isCircle: true,
    url: 'https://holiday-2023.s3.amazonaws.com/stickers/smiley.svg'
  },
  {
    name: 'thank-you',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/stickers/thank-you.svg'
  },
  {
    name: 'Wow',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/stickers/Wow.svg'
  },
  {
    name: 'Yay',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/stickers/Yay.svg'
  }
];

// camera.svg
// Property-1=heart eyes.svg
// Property-1=heart pixel.svg
// Property-1=smiley.svg
// Property-1=Variant5-1.svg
// Property-1=Variant5.svg
// Property-1=Variant7.svg
// Property-1=Variant8.svg

export const circles = [
  {
    name: 'camera',
    isCircle: true,
    url: 'https://holiday-2023.s3.amazonaws.com/stickers/circles/camera.svg'
  },
  {
    name: 'Property-1=heart-eyes',
    isCircle: true,
    url: 'https://holiday-2023.s3.amazonaws.com/stickers/circles/Property+1%3Dheart+eyes.svg'
  },
  {
    name: 'Property-1=heart-pixel',
    isCircle: true,
    url: 'https://holiday-2023.s3.amazonaws.com/stickers/circles/Property+1%3Dheart+pixel.svg'
  },
  {
    name: 'Property-1=smiley',
    isCircle: true,
    url: 'https://holiday-2023.s3.amazonaws.com/stickers/circles/Property+1%3Dsmiley.svg'
  },
  {
    name: 'Property-1=Variant5-1',
    isCircle: true,
    url: 'https://holiday-2023.s3.amazonaws.com/stickers/circles/Property+1%3DVariant5-1.svg'
  },
  {
    name: 'Property-1=Variant5',
    isCircle: true,
    url: 'https://holiday-2023.s3.amazonaws.com/stickers/circles/Property+1%3DVariant5.svg'
  },
  {
    name: 'Property-1=Variant7',
    isCircle: true,
    url: 'https://holiday-2023.s3.amazonaws.com/stickers/circles/Property+1%3DVariant7.svg'
  },
  {
    name: 'Property-1=Variant8',
    isCircle: true,
    url: 'https://holiday-2023.s3.amazonaws.com/stickers/circles/Property+1%3DVariant8.svg'
  }
];

// Property-1=Abstract.svg
// Property-1=countdown.svg
// Property-1=heart.svg
// Property-1=LCD1.svg
// Property-1=LCD2.svg
// Property-1=MIR.svg

// https://holiday-2023.s3.amazonaws.com/stickers/tvs/

export const tvs = [
  {
    name: 'Property-1=Abstract',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/stickers/tvs/Property+1%3DAbstract.svg'
  },
  {
    name: 'Property-1=countdown',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/stickers/tvs/Property+1%3Dcountdown.svg'
  },
  {
    name: 'Property-1=heart',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/stickers/tvs/Property+1%3Dheart.svg'
  },
  {
    name: 'Property-1=LCD1',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/stickers/tvs/Property+1%3DLCD1.svg'
  },
  {
    name: 'Property-1=LCD2',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/stickers/tvs/Property+1%3DLCD2.svg'
  },
  {
    name: 'Property-1=MIR',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/stickers/tvs/Property+1%3DMIR.svg'
  }
];


// Buzz.svg
// Really.svg
// Wow.svg
// Yay.svg

export const pills = [
  {
    name: 'Buzz',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/stickers/pills/Buzz.svg'
  },
  {
    name: 'Really',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/stickers/pills/Really.svg'
  },
  {
    name: 'Wow',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/stickers/pills/Wow.svg'
  },
  {
    name: 'Yay',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/stickers/pills/Yay.svg'
  }
];

// 2024-glasses.svg
// DreamTeam.svg
// happy-new-year.svg
// Love.svg
// Property-1=Default.svg
// Property-1=Variant2.svg
// thank-you.svg

export const random1 = [
  {
    name: '2024-glasses2',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/stickers/random1/2024-glasses.svg'
  },
  {
    name: 'DreamTeam2',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/stickers/random1/DreamTeam.svg'
  },
  {
    name: 'happy-new-year2',
    isCircle: true,
    url: 'https://holiday-2023.s3.amazonaws.com/stickers/random1/happy-new-year.svg'
  },
  {
    name: 'Love2',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/stickers/random1/Love.svg'
  },
  {
    name: 'Property-1=Default2',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/stickers/random1/Property+1%3DDefault.svg'
  },
  {
    name: 'Property-1=Variant22',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/stickers/random1/Property+1%3DVariant2.svg'
  },
  {
    name: 'thank-you2',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/stickers/random1/thank-you.svg'
  }
];

// Property-1=Default-1.svg
// Property-1=Default.svg
// Property-1=heart green.svg
// Property-1=heart violet.svg
// Property-1=Variant2-1.svg
// Property-1=Variant2.svg
// Property-1=Variant3.svg
// Property-1=Variant4.svg

export const random2 = [
  {
    name: 'Property-1=Default-12323',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/stickers/random2/Property+1%3DDefault-1.svg'
  },
  {
    name: 'Property-1=Default23',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/stickers/random2/Property+1%3DDefault.svg'
  },
  {
    name: 'Property-1=heart-green23',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/stickers/random2/Property+1%3Dheart+green.svg'
  },
  {
    name: 'Property-1=heart-violet23',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/stickers/random2/Property+1%3Dheart+violet.svg'
  },
  {
    name: 'Property-1=Variant2-123',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/stickers/random2/Property+1%3DVariant2-1.svg'
  },
  {
    name: 'Property-1=Variant223',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/stickers/random2/Property+1%3DVariant2.svg'
  },
  {
    name: 'Property-1=Variant323',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/stickers/random2/Property+1%3DVariant3.svg'
  },
  {
    name: 'Property-1=Variant4',
    isCircle: false,
    url: 'https://holiday-2023.s3.amazonaws.com/stickers/random2/Property+1%3DVariant4.svg'
  }
];
