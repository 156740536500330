import * as Styled from './ItemLoader.styles';

interface ItemLoaderProps {
  isOpen: boolean;
  text?: string;
  topSmall?: string;
}

const spring = {
  type: 'spring',
  stiffness: 900,
  damping: 30
};

const variants = {
  show: { opacity: 1, y: 0, display: 'flex' },
  hide: { opacity: 0, y: '-100%', transitionEnd: { display: 'none' } }
};

export const ItemLoader = ({ isOpen, text = 'Adding items...', topSmall = '62px' }: ItemLoaderProps) => {
  return (
    <Styled.ItemLoader
      animate={isOpen ? 'show' : 'hide'}
      variants={variants}
      transition={spring}
      initial={false}
      key={'itemLoader'}
      topSmall={topSmall}
    >
      <span>{text}</span>
      <Styled.Loader />
    </Styled.ItemLoader>
  );
};
